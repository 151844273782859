import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {byteSize, openFile, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './applicant-assessment.reducer';

export interface IApplicantAssessmentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ApplicantAssessmentDetail extends React.Component<IApplicantAssessmentDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { applicantAssessmentEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.applicantAssessment.detail.title">ApplicantAssessment</Translate> [
            <b>{applicantAssessmentEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="score">
                <Translate contentKey="evaluateyouApp.applicantAssessment.score">Score</Translate>
              </span>
            </dt>
            <dd>{applicantAssessmentEntity.score}</dd>
            <dt>
              <span id="maxScore">
                <Translate contentKey="evaluateyouApp.applicantAssessment.maxScore">Max Score</Translate>
              </span>
            </dt>
            <dd>{applicantAssessmentEntity.maxScore}</dd>
            <dt>
              <span id="examData">
                <Translate contentKey="evaluateyouApp.applicantAssessment.examData">Exam Data</Translate>
              </span>
            </dt>
            <dd>
              {applicantAssessmentEntity.examData ? (
                <div>
                  <a onClick={openFile(applicantAssessmentEntity.examDataContentType, applicantAssessmentEntity.examData)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                  <span>
                    {applicantAssessmentEntity.examDataContentType}, {byteSize(applicantAssessmentEntity.examData)}
                  </span>
                </div>
              ) : null}
            </dd>
            <dt>
              <span id="fullName">
                <Translate contentKey="evaluateyouApp.applicantAssessment.fullName">Full Name</Translate>
              </span>
            </dt>
            <dd>{applicantAssessmentEntity.fullName}</dd>
            <dt>
              <Translate contentKey="evaluateyouApp.applicantAssessment.assessment">Assessment</Translate>
            </dt>
            <dd>{applicantAssessmentEntity.assessment ? applicantAssessmentEntity.assessment.id : ''}</dd>
            <dt>
              <Translate contentKey="evaluateyouApp.applicantAssessment.assessmentEmail">Assessment Email</Translate>
            </dt>
            <dd>{applicantAssessmentEntity.assessmentEmail ? applicantAssessmentEntity.assessmentEmail.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/applicant-assessment" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/applicant-assessment/${applicantAssessmentEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ applicantAssessment }: IRootState) => ({
  applicantAssessmentEntity: applicantAssessment.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicantAssessmentDetail);
