import React from 'react';

import {Badge, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id}>
    <DropdownToggle nav caret className="d-flex align-items-center">
      <FontAwesomeIcon icon={props.icon} />
      {props.isHirer ? <div>&nbsp;<Badge variant="primary" pill>{props.creditVal}</Badge>&nbsp;</div> : ""}
      <span>&nbsp;&nbsp;{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
