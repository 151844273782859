import React from 'react';
import '../../assessment/assessment.scss';
import {Col, Label, Row} from 'reactstrap';
import "@pathofdev/react-tag-input/build/index.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {displayImage} from 'app/shared/util/common-utils';
import {Button} from '@material-ui/core';
import {WithOutContext as ReactTags} from 'react-tag-input-no-dnd';
import {ITag} from 'app/shared/model/tag.model';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {ITrainingStepperChildProps} from 'app/entities/training/training-stepper';
import {setFileData, Translate} from 'react-jhipster';
import {toast} from 'react-toastify';
import 'highlight.js/styles/monokai-sublime.css'

// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {quillFormats, quillModules} from 'app/shared/util/ui-utils';

interface TrainingDetailsProps extends ITrainingStepperChildProps{
  existingTags: Readonly<ITag[]>;

}

interface ReactTag{
    id: string;
    text: string;
}

interface TrainingDetailsState {
  errors: string[];
  error: boolean;
  errorMessage: any;

  image:any;
  tags: ReactTag[];

  description: string;
  outcome: string;

}

const KeyCodes = {
  comma: 188,
  enter: 13
};


const Font = ReactQuill.Quill.import('formats/font'); // <<<< ReactQuill exports it
Font.whitelist = ['Montserrat'] ; // allow ONLY these fonts and the default
ReactQuill.Quill.register(Font, true);

export class TrainingDetails extends React.Component<TrainingDetailsProps, TrainingDetailsState> {

  constructor(props) {
    super(props);
    console.log(this.props.model);
    this.state = {
      errors: [],
      error: false,
      errorMessage: {},
      tags: this.props.model.tags?this.props.model.tags.split(',').map(t=>{ return {id:t,text:t}}):[],
      image:this.props.model.image?this.props.model.image:null,
      description:this.props.model.description,
      outcome:this.props.model.outcome,
    };

  }

  componentWillReceiveProps(updatedProps) {
    this.setState({
      tags: updatedProps.model.tags?updatedProps.model.tags.split(',').map(t=>{ return {id:t,text:t}}):[],
      image:updatedProps.model.image?updatedProps.model.image:null,
    });
  }


  updateTags(tag: ReactTag) {
    if (this.state.tags.filter(t => t.text === tag.text).length === 0) {
      this.setState({tags: [...this.state.tags, tag]})
    }
  }

  onBlobChange (isAnImage, name) {
    setFileData(event, (contentType, data) => {
      this.setState({image:data});
    }, isAnImage);
  };

  private handleValidSubmit(errors, values) {
    if(!this.state.description){
      toast.error('Description is required');
      return;
    }

    if(!this.state.outcome){
      toast.error('Outcome is required');
      return;
    }


    if(errors.length === 0){
      const data = {
        ...values,
        tags: this.state.tags.map(t=>t.text).join(','),
        image: this.state.image,
        description: this.state.description,
        outcome: this.state.outcome,
      };

      this.props.populateModel(data);
      this.props.nextStep();
    }else{
      toast.error('Opps. There appears to be some information that is missing. Please fill in the required fields.')
    }
  }


  render() {
    return (
      <div>
        <AvForm id="training-form" onSubmit={(event, errors, values)=>this.handleValidSubmit(errors, values)}>
        <div className="page-title">
          <h4>
            <span><b><Translate contentKey="evaluateyouApp.assessment.steps.step1">Title</Translate></b></span>
          </h4>
          <h6>
            General Information about your training
          </h6>
        </div>

        {this.state.errors ? (<label style={{color: "red"}}>{
          this.state.errors.map(error => <div key={error}><span>{error}</span><br/></div>)}</label>) : (<span></span>)}


          <Row className="justify-content-center">
          <Col md="10">
            <Row className="form-row">
              <div >
                <Label id="logoLabel" for="logo">
                  <Translate contentKey="evaluateyouApp.training.image">Image</Translate>
                </Label>
                {
                  this.state.image ? (
                        <div>
                          <Row>
                            <Col md="6">
                              <span>
                                <img style={{padding: "10px", width: "100px"}}
                                     src={displayImage(this.state.image)}/>
                              </span>
                            </Col>
                            <Col md="2" className="justify-content-center">
                              <Button style={{height:'100%'}} onClick={()=>this.setState({image:null})}>
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </div>

                    ) :
                    <input id="file_logo" name="file_logo" type="file"
                           className="ml-3"
                           placeholder="Upload an image"
                           onChange={()=>this.onBlobChange(false, 'image')}/>
                }

              </div>
            </Row>
            <Row className="form-row">
              <Col md={10} xs={12}>

                <AvField
                    className="form-control"
                    name="name"
                    label={'Title'}
                    value={this.props.model.name}
                    required
                    id="name"
                    placeholder={'e.g Java Basics'}
                />


              </Col>
              <Col md={2} xs={12}>

                <AvField
                    className="form-control"
                    name="price"
                    type="number"
                    label={'Price'}
                    value={this.props.model.price}
                    required
                    id="price"
                    placeholder={'in MYR'}
                />
              </Col>
            </Row>

            <Row className="form-row">
              <Col md={12} xs={12}>
                <Label>Description</Label>
                <ReactQuill modules={quillModules}
                            formats={quillFormats}
                            theme="snow"
                            value={this.state.description}
                            onChange={(e)=>this.setState({description:e})} />
              </Col>
            </Row>


            <Row className="form-row">
              <Col md={12} xs={12}>
                <Label>Outcome</Label>
                <ReactQuill modules={quillModules}
                            formats={quillFormats}
                            theme="snow"
                            value={this.state.outcome}
                            onChange={(e)=>this.setState({outcome:e})} />
              </Col>
            </Row>

            <Row className="form-row">
              <Col xs={12} md={4}>
                  <AvField value={this.props.model.type?this.props.model.type:'Virtual'} type="select" required name="type" label="Type" helpMessage="where the training is conducted">
                    <option value={'Virtual'}>Virtual</option>
                    <option value={'On Premise'}>On Premise</option>
                  </AvField>
              </Col>
              <Col xs={12} md={4}>
                <AvField value={this.props.model.level?this.props.model.level:'Beginner'} type="select" required name="level" label="Difficulty" helpMessage="how difficult is this training">
                  <option value={'Beginner'}>Beginner</option>
                  <option value={'Intermediate'}>Intermediate</option>
                  <option value={'Advanced'}>Advanced</option>
                </AvField>
              </Col>
              <Col xs={12} md={4}>
                <label>Tags</label>
                <ReactTags
                    tags={this.state.tags}
                    suggestions={this.props.existingTags && this.props.existingTags.map(t => {
                      return {id: t.id.toString(), text: t.name}
                    })}
                    delimiters={[KeyCodes.comma, KeyCodes.enter]}
                    handleDelete={(i) => this.setState({tags: this.state.tags.filter((t, idx) => idx !== i)})}
                    handleAddition={(tag) => this.updateTags(tag)}
                    autocomplete
                    inline={true}
                />
              </Col>

            </Row>

            <Row className="form-row">
              <Col md={6} xs={12}>
                <AvField
                    className="form-control"
                    name="externalLink"
                    label={'External Link'}
                    value={this.props.model.externalLink}
                    id="externalLink"
                    placeholder={'e.g https://www.mytraining.com'}
                    helpMessage="a link that will redirect candidates to an external website (optional)"
                />
              </Col>
              <Col md={6} xs={12}>
                <AvField
                    className="form-control"
                    name="youTubeLink"
                    label={'YouTube Video'}
                    value={this.props.model.youTubeLink}
                    id="youTubeLink"
                    placeholder={'e.g https://www.youtube.com/watch?v=dQw4w9WgXcQ'}
                    helpMessage="a YouTube video that will play when candidates click on the training (optional)"
                />
              </Col>
            </Row>

          </Col>
        </Row>



        <div className="marginTop marginBottom" style={{display: "block", marginBottom: "20px"}}>
          <Button type="submit" className="pull-right btn-primary" variant="contained" color="primary">
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.next"></Translate>
                          </span>&nbsp;
            <FontAwesomeIcon icon="arrow-right"/>
          </Button>
        </div>

        </AvForm>
      </div>
    );
  }


}
