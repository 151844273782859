import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './assessment-audit.reducer';

export interface IAssessmentAuditDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AssessmentAuditDetail extends React.Component<IAssessmentAuditDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { assessmentAuditEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.assessmentAudit.detail.title">AssessmentAudit</Translate> [
            <b>{assessmentAuditEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="numOfQuestions">
                <Translate contentKey="evaluateyouApp.assessmentAudit.numOfQuestions">Num Of Questions</Translate>
              </span>
            </dt>
            <dd>{assessmentAuditEntity.numOfQuestions}</dd>
            <dt>
              <Translate contentKey="evaluateyouApp.assessmentAudit.assessment">Assessment</Translate>
            </dt>
            <dd>{assessmentAuditEntity.assessment ? assessmentAuditEntity.assessment.id : ''}</dd>
            <dt>
              <Translate contentKey="evaluateyouApp.assessmentAudit.audit">Audit</Translate>
            </dt>
            <dd>{assessmentAuditEntity.audit ? assessmentAuditEntity.audit.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/assessment-audit" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/assessment-audit/${assessmentAuditEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ assessmentAudit }: IRootState) => ({
  assessmentAuditEntity: assessmentAudit.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentAuditDetail);
