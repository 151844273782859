import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AuditQuestionOption from './audit-question-option';
import AuditQuestionOptionDetail from './audit-question-option-detail';
import AuditQuestionOptionUpdate from './audit-question-option-update';
import AuditQuestionOptionDeleteDialog from './audit-question-option-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AuditQuestionOptionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AuditQuestionOptionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AuditQuestionOptionDetail} />
      <ErrorBoundaryRoute path={match.url} component={AuditQuestionOption} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AuditQuestionOptionDeleteDialog} />
  </>
);

export default Routes;
