import axios from 'axios';

const apiUrl = 'api/users';

export class UserService {
  constructor() {}
  async getAll() {
    return axios
      .get(`${apiUrl}/getAll`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async getAllTimeZones() {
    return axios
      .get(`api/account/timezones`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default UserService;
