import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import QuestionFile from './question-file';
import QuestionFileDetail from './question-file-detail';
import QuestionFileUpdate from './question-file-update';
import QuestionFileDeleteDialog from './question-file-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={QuestionFileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={QuestionFileUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={QuestionFileDetail} />
      <ErrorBoundaryRoute path={match.url} component={QuestionFile} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={QuestionFileDeleteDialog} />
  </>
);

export default Routes;
