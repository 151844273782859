import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MyAuditQuestionOption from './my-audit-question-option';
import MyAuditQuestionOptionDetail from './my-audit-question-option-detail';
import MyAuditQuestionOptionUpdate from './my-audit-question-option-update';
import MyAuditQuestionOptionDeleteDialog from './my-audit-question-option-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MyAuditQuestionOptionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MyAuditQuestionOptionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MyAuditQuestionOptionDetail} />
      <ErrorBoundaryRoute path={match.url} component={MyAuditQuestionOption} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={MyAuditQuestionOptionDeleteDialog} />
  </>
);

export default Routes;
