import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Translate, translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getEntities as getCourses} from 'app/entities/course/course.reducer';
import {createEntity, getEntity, reset, updateEntity} from './major.reducer';

export interface IMajorUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IMajorUpdateState {
  isNew: boolean;
  courseId: string;
}

export class MajorUpdate extends React.Component<IMajorUpdateProps, IMajorUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      courseId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getCourses();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { majorEntity } = this.props;
      const entity = {
        ...majorEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/major');
  };

  render() {
    const { majorEntity, courses, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="evaluateyouApp.major.home.createOrEditLabel">
              <Translate contentKey="evaluateyouApp.major.home.createOrEditLabel">Create or edit a Major</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : majorEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="major-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="major-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="major-name">
                    <Translate contentKey="evaluateyouApp.major.name">Name</Translate>
                  </Label>
                  <AvField
                    id="major-name"
                    type="text"
                    name="name"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="major-description">
                    <Translate contentKey="evaluateyouApp.major.description">Description</Translate>
                  </Label>
                  <AvField id="major-description" type="text" name="description" />
                </AvGroup>
                <AvGroup>
                  <Label for="major-course">
                    <Translate contentKey="evaluateyouApp.major.course">Course</Translate>
                  </Label>
                  {
                      courses &&
                      <AvInput
                          id="major-course"
                          type="select"
                          className="form-control"
                          name="course.id"
                          required
                      >
                        <option value="" key="0" />
                        {courses
                            ? courses.map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                            ))
                            : null}
                      </AvInput>
                  }

                  <AvFeedback>
                    <Translate contentKey="entity.validation.required">This field is required.</Translate>
                  </AvFeedback>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/major" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  courses: storeState.course.entities,
  majorEntity: storeState.major.entity,
  loading: storeState.major.loading,
  updating: storeState.major.updating,
  updateSuccess: storeState.major.updateSuccess
});

const mapDispatchToProps = {
  getCourses,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MajorUpdate);
