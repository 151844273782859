import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Translate, translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';

import {createEntity, getEntity, reset, updateEntity} from './credit-history.reducer';
import {convertDateTimeFromServer, convertDateTimeToServer} from 'app/shared/util/date-utils';

export interface ICreditHistoryUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICreditHistoryUpdateState {
  isNew: boolean;
}

export class CreditHistoryUpdate extends React.Component<ICreditHistoryUpdateProps, ICreditHistoryUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  saveEntity = (event, errors, values) => {
    values.transactionTime = convertDateTimeToServer(values.transactionTime);

    if (errors.length === 0) {
      const { creditHistoryEntity } = this.props;
      const entity = {
        ...creditHistoryEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/credit-history');
  };

  render() {
    const { creditHistoryEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="evaluateyouApp.creditHistory.home.createOrEditLabel">
              <Translate contentKey="evaluateyouApp.creditHistory.home.createOrEditLabel">Create or edit a CreditHistory</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : creditHistoryEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="credit-history-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="credit-history-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="amountLabel" for="credit-history-amount">
                    <Translate contentKey="evaluateyouApp.creditHistory.amount">Amount</Translate>
                  </Label>
                  <AvField
                    id="credit-history-amount"
                    type="text"
                    name="amount"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="transactionIdLabel" for="credit-history-transactionId">
                    <Translate contentKey="evaluateyouApp.creditHistory.transactionId">Transaction Id</Translate>
                  </Label>
                  <AvField id="credit-history-transactionId" type="text" name="transactionId" />
                </AvGroup>
                <AvGroup>
                  <Label id="transactionTimeLabel" for="credit-history-transactionTime">
                    <Translate contentKey="evaluateyouApp.creditHistory.transactionTime">Transaction Time</Translate>
                  </Label>
                  <AvInput
                    id="credit-history-transactionTime"
                    type="datetime-local"
                    className="form-control"
                    name="transactionTime"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? null : convertDateTimeFromServer(this.props.creditHistoryEntity.transactionTime)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="statusLabel" for="credit-history-status">
                    <Translate contentKey="evaluateyouApp.creditHistory.status">Status</Translate>
                  </Label>
                  <AvField
                    id="credit-history-status"
                    type="text"
                    name="status"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="dataLabel" for="credit-history-data">
                    <Translate contentKey="evaluateyouApp.creditHistory.data">Data</Translate>
                  </Label>
                  <AvField
                    id="credit-history-data"
                    type="text"
                    name="data"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 6000, errorMessage: translate('entity.validation.maxlength', { max: 6000 }) }
                    }}
                  />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/credit-history" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  creditHistoryEntity: storeState.creditHistory.entity,
  loading: storeState.creditHistory.loading,
  updating: storeState.creditHistory.updating,
  updateSuccess: storeState.creditHistory.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditHistoryUpdate);
