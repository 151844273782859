import React from 'react';
import {Card} from '@material-ui/core';
import {Col, Row} from 'reactstrap';

export const CompletedAssessments = (props:{assessments:any[]}) =>{

    const {assessments} = props;


    return (
        <Card className="w-100">
            <Row className="py-4">
                <Col xs={2}>
                    <img src="content/images/multi-checkbox.png" height={46} width={46}/>
                </Col>
                <Col xs={10}>
                    <Row><b>ASSESSMENTS TAKEN</b></Row>
                    <Row><b>{assessments.length}</b></Row>
                </Col>
            </Row>
        </Card>
    );

}
