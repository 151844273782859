export const MARK_CONSTANTS = [{ value: 1, label: 'Easy' }, { value: 2, label: 'Medium' }, { value: 3, label: 'Hard' }];
export const MAX_SCORE = 30;

export const CONSTANTS = { EXAM_DATA: 'EXAM_DATA', REGISTER_ROLE: 'REGISTER_ROLE', SUCCESS: 1, FAILED: 0, FULL_NAME: "FULL_NAME" };

export const QUESTION_ANSWERS = { YES: 1, NO: 0, UNSELECTED: -1 };
export const PAYMENT_CONVERT_UNIT = { AMOUNT: 0.99 };

export const COUPON_STATUS = { ACCEPTED: 1, INVALID_CODE: -1, USED: -2, USAGE_LIMITED: -3, EXPIRED: -4 };

export const QUESTION_TYPES = [{ label: 'Multiple Choice', value: 3 }, { label: 'Single Choice', value: 4 }];
export const MULTI_CHOICE_TYPE = 3,
  SINGLE_CHOICE_TYPE = 4,
  SCORE_YES = 1,
  SCORE_NO = 0;
export const ANSWER_TYPES = [{ label: '', value: -1 }, { label: 'Yes', value: 1 }, { label: 'No', value: 0 }];
