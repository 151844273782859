import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Translate, translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';

import {createEntity, getEntity, reset, updateEntity} from './audit.reducer';
import {Card} from '@material-ui/core';

export interface IAuditUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface IAuditUpdateState {
    isNew: boolean;
}

export class AuditUpdate extends React.Component<IAuditUpdateProps, IAuditUpdateState> {
    constructor(props) {
        super(props);
        this.state = {
            isNew: !this.props.match.params || !this.props.match.params.id
        };
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
            this.handleClose();
        }
    }

    componentDidMount() {
        if (this.state.isNew) {
            this.props.reset();
        } else {
            this.props.getEntity(this.props.match.params.id);
        }
    }

    saveEntity = (event, errors, values) => {
        if (errors.length === 0) {
            const {auditEntity} = this.props;
            const entity = {
                ...auditEntity,
                ...values
            };

            if (this.state.isNew) {
                this.props.createEntity(entity);
            } else {
                this.props.updateEntity(entity);
            }
        }
    };

    handleClose = () => {
        this.props.history.push('/audit');
    };

    render() {
        const {auditEntity, loading, updating} = this.props;
        const {isNew} = this.state;

        return (
            <Row className={'justify-content-center w-100'}>
                <Col xs={12} md={11}>
                    <Card className={'py-3 px-3'}>
                        <Row className="justify-content-center">
                            <Col md="8">
                                <h2 id="evaluateyouApp.audit.home.createOrEditLabel">
                                    <Translate
                                        contentKey="evaluateyouApp.audit.home.createOrEditLabel">Create
                                        or edit a Audit</Translate>
                                </h2>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md="8">
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <AvForm model={isNew ? {} : auditEntity}
                                            onSubmit={this.saveEntity}>
                                        {!isNew ? (
                                            <AvGroup>
                                                <AvInput id="audit-id" type="hidden"
                                                         className="form-control" name="id" required
                                                         readOnly/>
                                            </AvGroup>
                                        ) : null}
                                        <AvGroup>
                                            <Label id="nameLabel" for="audit-name">
                                                <Translate
                                                    contentKey="evaluateyouApp.audit.name">Name</Translate>
                                            </Label>
                                            <AvField
                                                id="audit-name"
                                                type="text"
                                                name="name"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: translate('entity.validation.required')
                                                    },
                                                    maxLength: {
                                                        value: 500,
                                                        errorMessage: translate('entity.validation.maxlength', {max: 500})
                                                    }
                                                }}
                                            />
                                        </AvGroup>
                                        <AvGroup>
                                            <Label id="descriptionLabel" for="audit-description">
                                                <Translate
                                                    contentKey="evaluateyouApp.audit.description">Description</Translate>
                                            </Label>
                                            <AvField
                                                id="audit-description"
                                                type="text"
                                                name="description"
                                                validate={{
                                                    maxLength: {
                                                        value: 1000,
                                                        errorMessage: translate('entity.validation.maxlength', {max: 1000})
                                                    }
                                                }}
                                            />
                                        </AvGroup>
                                        <AvGroup>
                                            <Label id="isCoreLabel" check>
                                                <AvInput id="audit-isCore" type="checkbox"
                                                         className="form-control" name="isCore"/>
                                                <Translate contentKey="evaluateyouApp.audit.isCore">Is
                                                    Core</Translate>
                                            </Label>
                                        </AvGroup>
                                        <Button tag={Link} id="cancel-save" to="/audit" replace
                                                color="info">
                                            <FontAwesomeIcon icon="arrow-left"/>
                                            &nbsp;
                                            <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                                        </Button>
                                        &nbsp;
                                        <Button color="primary" id="save-entity" type="submit"
                                                disabled={updating}>
                                            <FontAwesomeIcon icon="save"/>
                                            &nbsp;
                                            <Translate
                                                contentKey="entity.action.save">Save</Translate>
                                        </Button>
                                    </AvForm>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

        );
    }
}

const mapStateToProps = (storeState: IRootState) => ({
    auditEntity: storeState.audit.entity,
    loading: storeState.audit.loading,
    updating: storeState.audit.updating,
    updateSuccess: storeState.audit.updateSuccess
});

const mapDispatchToProps = {
    getEntity,
    updateEntity,
    createEntity,
    reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuditUpdate);
