import React, {useEffect, useState} from 'react';
import {Button, Card} from '@material-ui/core';
import {Col, Row} from 'reactstrap';
import moment from 'moment/moment.js';
import {Link} from 'react-router-dom';
import {IAssessmentEmail} from "app/shared/model/assessment-email.model";


export const AssessmentCandidateInPastWeek = (props:{assessmentCandidates:IAssessmentEmail[]}) =>{

  const {assessmentCandidates} = props;
  const [inProgressTrainings, setInProgressTrainings] = useState<number>(0);


  useEffect(()=>{

    if(assessmentCandidates){
      const today = new Date();
      const lastWeek = new Date(moment().subtract(7, "days").format("YYYY-MM-DD "));

      const uniqueAssessmentIds = assessmentCandidates.filter(ut => new Date(ut.createdDate) >= lastWeek).map(ut=>ut.email).filter((ut, i, a) => a.indexOf(ut) === i);

      let inProgressTrainingsCount = 0;
      uniqueAssessmentIds.forEach(t=>{
        const assessmentDates = assessmentCandidates.filter(ut=>ut.email === t).filter(ut => new Date(ut.createdDate) >= lastWeek);
        const recentAssessmentDates = assessmentDates.filter(ut=>new Date(ut.createdDate) <= today && new Date(ut.createdDate) >= lastWeek);

        console.log(" assessmentDates: " + assessmentDates.length);
        console.log(" recentAssessmentDates: " + recentAssessmentDates.length);


        if(recentAssessmentDates.length === assessmentDates.length){
          inProgressTrainingsCount += 1;
        }
      })
      setInProgressTrainings(inProgressTrainingsCount);
    }


  },[assessmentCandidates])


  return (
    <Card className="w-100">
      <Row className="py-4">
        <Col xs={10}>
          <Row><b>Recent Assessment Candidates</b></Row>
          <Row className={'displayNumber'}><b>{inProgressTrainings}</b></Row>
          <Row style={{marginTop: 20}}>
            <Button>
              <Link to={{
                pathname: "/candidates-table",
                state: {
                  assessmentCandidates
                }
              }}>
                VIEW
              </Link>
            </Button>
          </Row>
        </Col>
      </Row>
    </Card>
  );

}
