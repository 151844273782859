import React from 'react';
import '../assessment.scss';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {connect} from 'react-redux';
import {IPaginationBaseState, Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";
import AssessmentService from 'app/shared/service/assessment.service';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAssessment} from 'app/shared/model/assessment.model';
import {IRootState} from "app/shared/reducers";

interface ExistingAssessmentProps extends StateProps{

  model: IAssessment;
  titleCallback: any;
  descriptionCallback: any;
  clearBlob: any;
  blobChangeCallback: any;
  percentCallback: any;
  allowViewScoreCallback: any;

  nextStep: any;
}

interface ExistingAssessmentState extends IPaginationBaseState {
  errors: string[];
  assessments: IAssessment[];
  dropdownTitle: string;
  dropdownToggle: boolean;
}

export class ExistingAssessment extends React.Component<ExistingAssessmentProps, ExistingAssessmentState> {
  assessmentService: AssessmentService;

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      assessments: [],
      dropdownTitle: this.props.model.title.length > 0 ? this.props.model.title : "Select Assessment",
      dropdownToggle: false,
      itemsPerPage: 0,
      sort: "",
      order: "",
      activePage: 0,
    };
    this.assessmentService = new AssessmentService();
  }

  componentDidMount() {
    this.getEntities();
  }

  getEntities = () => {
    const {activePage, itemsPerPage, sort, order,} = this.state;

    this.assessmentService.loadByCreated(activePage - 1, itemsPerPage, `${sort},${order}&createdBy.contains=` + this.props.account.login).then(response => {
      console.log("DATA {}", response["data"]);
      const assessments = response["data"];
      this.setState({ assessments: assessments});
    });
  };

  toggleDropdown = () => {
    if(this.state.dropdownToggle===true)
      this.setState({dropdownToggle: false});
    else
      this.setState({dropdownToggle: true});
  }

  dropDown = () => {
    return (
      <Dropdown direction="right" isOpen={this.state.dropdownToggle} toggle={this.toggleDropdown}>
        <DropdownToggle caret>
          {this.state.dropdownTitle}
        </DropdownToggle>
        <DropdownMenu>
          {this.state.assessments.map(q => (
            <DropdownItem key={q.id} onClick={event => this.selectAssessment(q)}><span style={{fontWeight:"bold"}}>{q.title}</span>&nbsp;&nbsp;&nbsp;[{q.expiryDate.toString().substr(0, 10)}]</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }

  selectAssessment = (value: IAssessment) => {
    console.log("VALUE IS {}",value);
    this.setState({dropdownTitle: value.title});
    this.props.titleCallback(value.title);
    this.props.percentCallback(value.percent);
    this.props.descriptionCallback(value.description);
    this.props.blobChangeCallback(value.logo);
    this.props.allowViewScoreCallback(value.allowViewScore);
    console.log(this.props.model);
  }



  validate(): void {
    this.setState({errors: []}); // clear existing errors
    const currentErrors = [];

    if(this.state.dropdownTitle==="Select Assessment"){
      currentErrors.push('Assessment not selected');
    }

    this.setState({errors: currentErrors});
    if (currentErrors.length === 0) {
      this.props.nextStep();
    }

  }

  render() {

    return (
      <div>
        <div className="page-title">
          <h4>
                        <span><b><Translate
                          contentKey="evaluateyouApp.assessment.steps.step1Exist">Assessment</Translate></b></span>
          </h4>
        </div>
        {this.state.errors ? (<label style={{color: "red"}}>{
          this.state.errors.map(error => <div key={error}><span>{error}</span><br/></div>)}</label>) : (<span></span>)}

        {this.dropDown()}


        <div className="marginTop marginBottom"
             style={{display: "block", marginBottom: "20px"}}>
          <Button onClick={() => this.validate()} className="pull-right btn-primary">
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.next"></Translate>
                          </span>&nbsp;
            <FontAwesomeIcon icon="arrow-right"/>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
)(ExistingAssessment);

