import {IUser} from 'app/shared/model/user.model';

export interface IEducation {
  id?: number;
  level?: string;
  yearFrom?: Date;
  yearTo?: Date;
  institution?: string;
  user?: IUser;
}

export const defaultValue: Readonly<IEducation> = {
  level: '',
  yearFrom: null,
  yearTo: null,
  institution: ''
};
