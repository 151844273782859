import './training.css';

import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {Card, CardContent} from "@material-ui/core"
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {TextFormat} from 'react-jhipster';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {IRootState} from 'app/shared/reducers';
import {enrollTraining, getEntity} from './training.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';
import {toast} from 'react-toastify';

export interface IEnrollTrainingProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export interface ISessionDate{
  sessionId: number;
  dateId: number;
}

export interface IUserEnrollment{
  scheduleId: number;
  selectedDates: ISessionDate[],
  trainingId: number
}


export class EnrollTraining extends React.Component<IEnrollTrainingProps,IUserEnrollment> {

  constructor(props) {
    super(props);
    this.state = {
      scheduleId: 0,
      selectedDates: [],
      trainingId: parseInt(this.props.match.params.id,10)
    }

  }

  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  componentWillReceiveProps(updatedProps) {
    if (updatedProps.training) {
      this.setState({
        trainingId: updatedProps.training.id
      })
    }
  }


  displayImage = (logo) => {
    if (logo) {
      const imageUrl = "data:image/;base64," + logo;
      return imageUrl;
    } else {
      return 'content/images/training-table.jpg';
    }

  }

  setDate(dateId: number, sessionId:number){
    const {selectedDates} = this.state;
    const newSelectedDates = selectedDates.filter(date => date.dateId !== dateId).filter(date => date.sessionId !== sessionId);
    newSelectedDates.push({dateId, sessionId});
    this.setState({selectedDates: newSelectedDates});
  }

  getDate(sessionId:number){
    const {selectedDates} = this.state;
    const selectedDate = selectedDates.find(date => date.sessionId === sessionId);
    return selectedDate?selectedDate.dateId:-1;
  }

  submitDates(){
      const totalAvailableSessions = this.props.trainingEntity.trainingSchedules.find(s=>s.id===this.state.scheduleId).sessions.length;
      if(this.state.selectedDates.length < totalAvailableSessions){
        toast.error('Sorry but you need to pick a date from each available session');
        return;
      }
      this.props.enrollTraining(this.state);
      this.props.history.push('/training-list');
  }

  render() {
    const {trainingEntity} = this.props;

    return (
      <Row className="px-5 mb-5 justify-content-center">
        <Col md={6} xs={12}>
          <Card>
          <CardContent>
            <Row>
              <Col md={12} xs={12}>
                <Row className="content mt-5 ml-3">
                  <span className="text-left" style={{textAlign: 'left', fontSize: 24}}>
                    <b>Pick your dates</b>
                  </span>
                </Row>
                <Row className="ml-3">
                  <h6>
                    Pick a date from each session.
                  </h6>
                </Row>


                <Row className="justify-content-left mt-3">
                  <Col xs={12}>

                    <FormControl style={{textAlign: 'left'}} fullWidth>
                      <InputLabel  id="demo-simple-select-label">Schedule</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.scheduleId}
                          label="Session"
                          onChange={(e)=>this.setState({scheduleId:e.target.value as number})}
                      >

                        {trainingEntity.trainingSchedules && trainingEntity.trainingSchedules.length > 0 && trainingEntity.trainingSchedules.map((schedule, i) => (
                            <MenuItem key={schedule.id} value={schedule.id}>{schedule.scheduleName}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>

                  </Col>
                </Row>


                <Row className="justify-content-left mt-5">

                  <Col xs={12}>
                    {
                        this.state.scheduleId !== 0 && trainingEntity.trainingSchedules.find(s=>s.id===this.state.scheduleId).sessions.sort((s1,s2)=>s1.id - s2.id).map((session, idx2) => {
                        return (<div key={idx2} className="my-3">
                          <Row className="justify-content-left my-2">
                            <Col md={12} className="align-items-left"><Label>{session.sessionName}</Label></Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              {
                                session.dates.map((date, idx3) => {

                                  return (
                                      <RadioGroup key={idx3} aria-label="trainingDate" name="trainingDate" value={this.getDate(session.id)} onChange={(e)=>this.setDate(parseInt(e.target.value,10),session.id)}>
                                        <Row className="my-2">
                                          <FormControlLabel value={date.id} control={<Radio />} label={
                                            <>
                                              <TextFormat
                                                  type="date"
                                                  value={date.startDateTime}
                                                  format={APP_DATE_FORMAT}/>
                                              &nbsp;&nbsp;-&nbsp;&nbsp;
                                              <TextFormat
                                              type="date"
                                              value={date.endDateTime}
                                              format={APP_DATE_FORMAT}/>
                                            </>
                                          }/>

                                        </Row>
                                      </RadioGroup>
                                        )
                                })
                              }
                            </Col>
                          </Row>

                        </div>);
                      })
                    }

                  </Col>

                </Row>
              </Col>


              <Col xs={12}>
                <Button
                  variant="contained"
                  className="mt-3"
                  style={{width:"50%"}}
                  onClick={() => this.submitDates()}
                  color="primary"
                >
                  <span className="cardButtonDescription">Enroll Now</span>
                </Button>
              </Col>

            </Row>
          </CardContent>
        </Card>.
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({
                           training,
                         }: IRootState) => ({
  trainingEntity: training.entity,
});

const mapDispatchToProps = {
  getEntity,
  enrollTraining
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnrollTraining);
