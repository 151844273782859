import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AdminConfig from './admin-config';
import AdminConfigDetail from './admin-config-detail';
import AdminConfigUpdate from './admin-config-update';
import AdminConfigDeleteDialog from './admin-config-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AdminConfigUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AdminConfigUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AdminConfigDetail} />
      <ErrorBoundaryRoute path={match.url} component={AdminConfig} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AdminConfigDeleteDialog} />
  </>
);

export default Routes;
