import '../assessment.scss';
import React from 'react';
import {Button} from 'reactstrap';
import {Translate} from 'react-jhipster';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {validateEmail} from 'app/shared/util/common-utils';

interface CandidateDetailsProps{
    emails: any;
    credit: any;

    changeEmail:Function;
    previousStep:Function;
    nextStep: Function;
    allowPublic:boolean;

}

interface CandidateDetailsState{
    errors: string[]
}

export class CandidateDetails extends React.Component<CandidateDetailsProps,CandidateDetailsState> {

    constructor(props){
        super(props);
        this.state = {errors:[]};
    }

    render() {
        return (
            <div>
                {
                    this.props.allowPublic?
                        <div className="my-5">
                            <h4>
                                <span><Translate contentKey="evaluateyouApp.assessment.steps.step4">Title</Translate></span>
                            </h4>
                            <p className="label-secondary">All candidates can join public assessments. No selection of candidates is necessary.</p>
                        </div>
                        :
                        <div>
                            <div className="page-title">
                                <h4>
                                    <span><Translate contentKey="evaluateyouApp.assessment.steps.step4">Title</Translate></span>
                                </h4>
                                <p className="label-secondary">Enter valid emails and hit ENTER</p>
                            </div>

                            {this.state.errors ? (<label style={{color: "red"}}>{
                                this.state.errors.map(error=><div key={error}><span>{error}</span><br/></div>)}</label>) : (<span></span>)}

                            <div style={{marginBottom:30}}>
                                <ReactTagInput
                                    tags={this.props.emails} placeholder="Enter Candidate Emails and hit ENTER"
                                    onChange={(newTags) => this.props.changeEmail(newTags)}
                                    validator={ (value) => this.validateTagInput(value)}
                                />
                            </div>
                        </div>

                }




                <div className="marginTop marginBottom">
                    <Button onClick={() => this.props.previousStep()} color="info" className="btn-primary">
                        <FontAwesomeIcon icon="arrow-left" />
                        &nbsp;
                        <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
                    </Button>
                    <Button onClick={() => this.validate()} color="info" className="pull-right btn-primary">
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.next"></Translate>
                      </span>&nbsp;
                        <FontAwesomeIcon icon="arrow-right" />
                    </Button>
                </div>
            </div>
        )
    }

    validateTagInput = (value) =>{
        const emails = this.props.emails;
        const indexOf = emails.indexOf(value);
        if(indexOf === -1 && validateEmail(value)){
            const credit = {...this.props.credit};
            // const errors = {...this.state.errors};

            // if number of emails is larger than available credit
            if(emails.length + 1 > credit.credit){
                const errors = [];
                errors.push("Insufficient credits to add more candidates");
                this.setState({errors: errors});
                return false;
            }
            return true;
        }
        return false;
    }

    private validate():void {
        const errors = [];
        if(this.props.emails.length === 0 && !this.props.allowPublic){
            errors.push("At least 1 candidate needs to be selected");
            this.setState({errors:errors});
        }else{
            this.props.nextStep();
        }


    }
}
