import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities} from './admin-config.reducer';

export interface IAdminConfigProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export class AdminConfig extends React.Component<IAdminConfigProps> {
  componentDidMount() {
    this.props.getEntities();
  }

  render() {
    const { adminConfigList, match } = this.props;
    return (
      <div>
        <h2 id="admin-config-heading">
          <Translate contentKey="evaluateyouApp.adminConfig.home.title">Admin Configs</Translate>
        </h2>
        <div className="table-responsive">
          {adminConfigList && adminConfigList.length > 0 ? (
            <Table responsive aria-describedby="admin-config-heading">
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="global.field.id">ID</Translate>
                  </th>
                  <th>
                    <Translate contentKey="evaluateyouApp.adminConfig.costPerCredit">Cost Per Credit</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {adminConfigList.map((adminConfig, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${adminConfig.id}`} color="link" size="sm">
                        {adminConfig.id}
                      </Button>
                    </td>
                    <td>{adminConfig.costPerCredit}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${adminConfig.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${adminConfig.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="evaluateyouApp.adminConfig.home.notFound">No Admin Configs found</Translate>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ adminConfig }: IRootState) => ({
  adminConfigList: adminConfig.entities
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminConfig);
