import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './coupon.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';

export interface ICouponDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CouponDetail extends React.Component<ICouponDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { couponEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.coupon.detail.title">Coupon</Translate> [<b>{couponEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="expiryDate">
                <Translate contentKey="evaluateyouApp.coupon.expiryDate">Expiry Date</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={couponEntity.expiryDate} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="credit">
                <Translate contentKey="evaluateyouApp.coupon.credit">Credit</Translate>
              </span>
            </dt>
            <dd>{couponEntity.credit}</dd>
            <dt>
              <span id="usedBy">
                <Translate contentKey="evaluateyouApp.coupon.usedBy">Used By</Translate>
              </span>
            </dt>
            <dd>{couponEntity.usedBy}</dd>
            <dt>
              <span id="usageLimit">
                <Translate contentKey="evaluateyouApp.coupon.usageLimit">Usage Limit</Translate>
              </span>
            </dt>
            <dd>{couponEntity.usageLimit}</dd>
          </dl>
          <Button tag={Link} to="/coupon" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/coupon/${couponEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ coupon }: IRootState) => ({
  couponEntity: coupon.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponDetail);
