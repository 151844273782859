import React, {useEffect, useState} from 'react';
import {Card} from '@material-ui/core';
import {Col, Row} from 'reactstrap';
import {IUserTraining} from 'app/shared/model/user-training.model';

export const InProgressTraining = (props:{userTrainings:IUserTraining[]}) =>{

    const {userTrainings} = props;
    const [inProgressTrainings, setInProgressTrainings] = useState<number>(0);


    useEffect(()=>{

        if(userTrainings && userTrainings.length > 0){
            const today = new Date();

            // count number of unique trainings
            const uniqueTrainingsIds = userTrainings.map(ut=>ut.training.id).filter((ut, i, a) => a.indexOf(ut) === i);

            let inProgressTrainingsCount = 0;
            uniqueTrainingsIds.forEach(t=>{
                const trainingDates = userTrainings.filter(ut=>ut.training.id === t);
                const completedTrainingDates = trainingDates.filter(ut=>new Date(ut.trainingDate.endDateTime) < today);

                if(completedTrainingDates.length < trainingDates.length){
                    inProgressTrainingsCount += 1;
                }
            })

            setInProgressTrainings(inProgressTrainingsCount);
        }



    },[userTrainings])



    return (
        <Card className="w-100">
            <Row className="py-4">
                <Col xs={2}>
                    <img src="content/images/clock.png" height={47} width={47}/>
                </Col>
                <Col xs={10}>
                    <Row><b>TRAINING IN PROGRESS</b></Row>
                    <Row><b>{inProgressTrainings}</b></Row>
                </Col>
            </Row>
        </Card>
    );

}
