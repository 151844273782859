import React, {useEffect, useState} from 'react';
import {Card} from '@material-ui/core';
import {Col, Row} from 'reactstrap';
import {IUserTraining} from 'app/shared/model/user-training.model';

export const CompletedTraining = (props:{userTrainings:IUserTraining[]}) =>{

    const {userTrainings} = props;
    const [completedTrainings, setCompletedTrainings] = useState<number>(0);


    useEffect(()=>{

        if(userTrainings && userTrainings.length > 0){
            const today = new Date();

            // count number of unique trainings
            const uniqueTrainingsIds = userTrainings.map(ut=>ut.training.id).filter((ut, i, a) => a.indexOf(ut) === i);

            let totalCompletedTrainings = 0;
            uniqueTrainingsIds.forEach(t=>{
                const trainingDates = userTrainings.filter(ut=>ut.training.id === t);
                // TODO get attendance from a new entity instead of comparing end dates
                const completedTrainingDates = trainingDates.filter(ut=>new Date(ut.trainingDate.endDateTime) < today);

                if(completedTrainingDates.length === trainingDates.length){
                    totalCompletedTrainings += 1;
                }
            })

            setCompletedTrainings(totalCompletedTrainings);
        }


    },[userTrainings])



    return (
        <Card className="w-100">
            <Row className="py-4">
                <Col xs={2}>
                    <img src="content/images/two-ticks.png" height={46} width={65}/>
                </Col>
                <Col xs={10}>
                    <Row><b>COMPLETED TRAINING</b></Row>
                    <Row><b>{completedTrainings}</b></Row>
                </Col>
            </Row>
        </Card>
    );

}
