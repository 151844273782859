import hljs from 'highlight.js';

export const renderMarkDown = (text: string): string => {
  return text;
};

export const trimDescription = (text: string): string => {
  if (text.length > 80) {
    return text.substring(0, 80) + '...';
  } else {
    return text;
  }
};

export const quillModules = {
  syntax: {
    highlight: text => hljs.highlightAuto(text).value
  },
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
    ['code-block']
  ]
};

export const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'code-block'
];
