import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Card, Col, Row} from 'reactstrap';
import TextField from '@material-ui/core/TextField';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './training.reducer';
import {getTrainingDateDetails} from "app/entities/training-date/training-date.reducer";

export interface ITrainingDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ApplyTrainingDates extends React.Component<ITrainingDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
    const trainingId = this.props.match.params.id;
    this.props.getTrainingDateDetails(trainingId);

  }
  displayImage = (image) => {
    const imageUrl = "data:image/;base64," + image;
    return imageUrl;
  }
  render() {
    const { trainingEntity } = this.props;
    const { trainingDateEntity } = this.props;

    return (
      <Card className="col-xs-10 col-lg-6">

        <Row className="justify-content-center">
          <Col md="8">
            <h2 style={{textAlign:'center'}}>
              Training Dates
            </h2>
          </Col>
        </Row>


        <Row className="justify-content-center">
              {trainingDateEntity.map((trainingDate, i) => (
              <Row key={`entity-${i}`} className="form-row">
                <Col>


                    <TextField id="assessment-title"
                               label="Start Date Time"
                               variant="filled"
                               fullWidth
                               name="name"
                               disabled
                               value={trainingDate.startDateTime}

                    />
                      </Col>
<Col>
                <TextField id="assessment-title"
                           label="End Date Time"
                           variant="filled"
                           fullWidth
                           name="name"
                           disabled
                           value={trainingDate.endDateTime}

                />
</Col>
              </Row>
              ))}
        </Row>
        <div style={{textAlign:'center'}}>
          <Button tag={Link} to={`/training/${trainingEntity.id}/session`} color="info" size="sm">
                            <span className="d-none d-md-inline">
                             Back
                           </span>
          </Button>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = ({ training, trainingDate }: IRootState) => ({
  trainingEntity: training.entity,
  trainingDateEntity: trainingDate.trainingDateList
});

const mapDispatchToProps = { getEntity, getTrainingDateDetails };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyTrainingDates);
