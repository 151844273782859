import React from 'react';
import {Switch} from 'react-router-dom';
import {Card} from 'reactstrap';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AuditQuestion from './audit-question';
import AuditQuestionDetail from './audit-question-detail';
import AuditQuestionUpdate from './audit-question-update';
import AuditQuestionDeleteDialog from './audit-question-delete-dialog';

const Routes = ({ match }) => (
  <div>
    <Card className="main-card">
      <Switch>
        <ErrorBoundaryRoute exact path={`${match.url}/:auditId/new`} component={AuditQuestionUpdate} />
        <ErrorBoundaryRoute exact path={`${match.url}/:id/:rnd/edit`} component={AuditQuestionUpdate} />
        <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AuditQuestionDetail} />
        <ErrorBoundaryRoute path={match.url} component={AuditQuestion} />
      </Switch>
      <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AuditQuestionDeleteDialog} />
    </Card>
  </div>
);

export default Routes;
