import axios from 'axios';

const apiUrl = 'api/coupons';

export class CouponService {
  constructor() {}
  async validAndUpdateUsedCoupon(id) {
    return axios
      .post(`${apiUrl}/validAndUpdateUsedCoupon`, { id: id })
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default CouponService;
