import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './credit.reducer';

export interface ICreditDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CreditDetail extends React.Component<ICreditDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { creditEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.credit.detail.title">Credit</Translate> [<b>{creditEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="credit">
                <Translate contentKey="evaluateyouApp.credit.credit">Credit</Translate>
              </span>
            </dt>
            <dd>{creditEntity.credit}</dd>
            <dt>
              <span id="spentCredit">
                <Translate contentKey="evaluateyouApp.credit.spentCredit">Spent Credit</Translate>
              </span>
            </dt>
            <dd>{creditEntity.spentCredit}</dd>
          </dl>
          <Button tag={Link} to="/credit" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/credit/${creditEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ credit }: IRootState) => ({
  creditEntity: credit.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditDetail);
