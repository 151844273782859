import React from 'react';
import '../../assessment/assessment.scss';
import {Col, Row, Table} from 'reactstrap';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import {ITrainingSchedule} from "app/shared/model/training-schedule.model";
import {isBlank} from "app/shared/util/common-utils";
import {Button, Tooltip} from '@material-ui/core';
import {ITrainingStepperChildProps} from 'app/entities/training/training-stepper';
import {WarningBubble} from 'app/shared/components/warning-bubble';
import {countEnrollmentsInSchedule, hasEnrollments} from 'app/shared/util/training-utils';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

interface TrainingScheduleState {
  trainingSchedules: ITrainingSchedule[];
  trainingScheduleForm: ITrainingSchedule;
  errors: string[];
  addMode: boolean;
  error: boolean;

  errorMessage: any;

}

export class TrainingSchedule extends React.Component<ITrainingStepperChildProps, TrainingScheduleState> {

  constructor(props) {
    super(props);
    this.state = {
      trainingSchedules: this.props.model.trainingSchedules,
      trainingScheduleForm: {},
      errors: [],
      addMode: true,
      error: false,
      errorMessage: {}
    };
  }

  componentDidMount() {
    // call api or anything
    if (this.props.model.trainingSchedules) {
      this.setState({...this.state, trainingSchedules: this.props.model.trainingSchedules});
    }

  }

  saveAndGoNext(): void {
    this.props.populateModel({ trainingSchedules: this.state.trainingSchedules});
    this.props.nextStep();

  }

  saveAndGoBack() {
    this.props.populateModel({ trainingSchedules: this.state.trainingSchedules});
    this.props.prevStep();
  }

  addOrUpdateSchedule(): void {
    if (isBlank(this.state.trainingScheduleForm.scheduleName)) {
      this.setState({
        error: true,
        errorMessage: {
          scheduleName: "Name of schedule is required"
        }
      });
    }
    else if (this.state.addMode) {
      this.setState({
        trainingSchedules: [...this.state.trainingSchedules, {
          ...this.state.trainingScheduleForm,
          id: (this.state.trainingSchedules.length + 1),
          sessions: []
        }]
      });
    } else {
      this.state.trainingSchedules.forEach(ts => {
        if (ts.id === this.state.trainingScheduleForm.id) {
          ts.scheduleName = this.state.trainingScheduleForm.scheduleName;
        }
      });

      this.setState({trainingSchedules: [...this.state.trainingSchedules]});
    }


    this.setState({trainingScheduleForm: {}, addMode: true});
  }

  editTrainingSchedule(id: number): void {
    this.setState({trainingScheduleForm: this.state.trainingSchedules.filter(e => e.id === id)[0], addMode: false});
  }

  deleteTrainingSchedule(idx: number): void {
    this.state.trainingSchedules.splice(idx, 1);
    this.setState({trainingSchedules: [...this.state.trainingSchedules]});
  }


  render() {
    return (
      <div>
        <div className="page-title">
          <h4>
            <span><b>Schedules</b></span>
          </h4>
          <h6>
            A schedule represents a period at which training is to be conducted. You may
            then add multiple sessions to a schedule where each session represents a
            different set of lessons that are taught. <br/><br/>Example: <b>June Intake</b>
          </h6>
        </div>

        {
          hasEnrollments(this.props.model) &&
              <WarningBubble  description='Users have already been enrolled to this training. Some editable features will be limited'/>
        }

        {this.state.errors ? (<label style={{color: "red"}}>{
          this.state.errors.map(error => <div key={error}><span>{error}</span><br/>
          </div>)}</label>) : (<span></span>)}

        {
          this.state.trainingSchedules.length > 0 &&
          <Row>
            <Col md={12}>
              <Table responsive aria-describedby="assessment-heading" id="hoverMenu">
                <thead>
                <tr>
                  <th>Name</th>
                  {
                      hasEnrollments(this.props.model) &&
                      <th>Enrollments</th>
                  }
                  <th style={{width: 100, textAlign: 'left'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.trainingSchedules.map((trainingSchedule, idx) => (
                    <tr key={trainingSchedule.id}>
                      <td>{trainingSchedule.scheduleName}</td>
                      {
                          hasEnrollments(this.props.model) &&
                          <td>{countEnrollmentsInSchedule(trainingSchedule,this.props.model.userTrainings)}</td>
                      }
                      <td style={{width: 100, textAlign: 'left'}}>
                        <div className="text-left">
                          <div className="btn-group flex-btn-group-container">
                            <Button className="mr-3" onClick={() => this.editTrainingSchedule(trainingSchedule.id)}>
                              <span className="d-none d-md-inline ml-2">Edit</span>
                            </Button>

                            {
                              countEnrollmentsInSchedule(trainingSchedule,this.props.model.userTrainings) === 0?
                                <Button onClick={() => this.deleteTrainingSchedule(idx)}>
                                    <span className="d-none d-md-inline ml-2">Delete</span>
                                </Button>
                                :
                              <Tooltip title="Delete disabled since there are enrollments associated with this schedule" arrow>
                                  <div style={{display:'flex'}} className="align-items-center justify-content-center w-100">
                                    <FontAwesomeIcon icon={faExclamationCircle} size={'1x'}/>
                                  </div>
                              </Tooltip>
                            }

                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        }


        <Row>
          <Col md="6">
            <Row className="form-row">
              <Col xs={10}>
                <TextField id="assessment-title"
                           label="Schedule Name"
                           variant="filled"
                           fullWidth
                           name="scheduleName"
                           value={this.state.trainingScheduleForm.scheduleName ? this.state.trainingScheduleForm.scheduleName : ''}
                           onChange={(event) => this.setState({
                             trainingScheduleForm: {
                               ...this.state.trainingScheduleForm,
                               scheduleName: event.target.value
                             }
                           })}
                           error={this.state.errorMessage.scheduleName}
                           helperText="Please state a name for your training schedule."
                           required
                />
              </Col>
              <Col xs={2} className="justify-content-center my-auto" style={{height:56}}>
                  <Button onClick={() => this.addOrUpdateSchedule()}>
                          <span className="d-none d-md-inline">
                          {this.state.addMode ? 'Add' : 'Update'}
                          </span>
                  </Button>
              </Col>

            </Row>
          </Col>
        </Row>


        <div className="mt-5 mb-4">
          <Button onClick={() => this.saveAndGoBack()} variant="contained" color="primary">
            <FontAwesomeIcon icon="arrow-left"/>
            &nbsp;
            <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
          </Button>
          <Button onClick={() => this.saveAndGoNext()} variant="contained" color="primary"
                  className="pull-right">
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.next"></Translate>
                      </span>&nbsp;
            <FontAwesomeIcon icon="arrow-right"/>
          </Button>
        </div>
      </div>
    );
  }



}
