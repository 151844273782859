import React from 'react';
import {Switch} from 'react-router-dom';
import {Card} from 'reactstrap';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PaymentRequest from './payment-request';
import PaymentRequestDetail from './payment-request-detail';
import PaymentRequestUpdate from './payment-request-update';
import PaymentRequestDeleteDialog from './payment-request-delete-dialog';

const Routes = ({ match }) => (
  <div style={{paddingLeft: "280px"}}>
    <Card className="main-card">
      <Switch>
        <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PaymentRequestUpdate} />
        <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PaymentRequestUpdate} />
        <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PaymentRequestDetail} />
        <ErrorBoundaryRoute path={match.url} component={PaymentRequest} />
      </Switch>
      <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={PaymentRequestDeleteDialog} />
    </Card>
  </div>
);

export default Routes;
