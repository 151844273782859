import React from 'react';
import {Switch} from 'react-router-dom';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Assessment from './assessment';
import AssessmentDetail from './assessment-detail';
import AssessmentUpdate from './assessment-update';
import AssessmentDeleteDialog from './assessment-delete-dialog';
import ChooseAssessment from "app/entities/assessment/choose-assessment";

const Routes = ({ match }) => (
  <div>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/choose-assessment`} component={ChooseAssessment} />
      <ErrorBoundaryRoute exact path={`${match.url}/new/`} component={AssessmentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/new-custom-questions`} component={AssessmentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/new-existing-questions`} component={AssessmentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AssessmentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/:from`} component={AssessmentDetail} />
      <ErrorBoundaryRoute path={match.url} component={Assessment} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AssessmentDeleteDialog} />
  </div>
);

export default Routes;
