import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AssessmentEmail from './assessment-email';
import AssessmentEmailDetail from './assessment-email-detail';
import AssessmentEmailUpdate from './assessment-email-update';
import AssessmentEmailDeleteDialog from './assessment-email-delete-dialog';

const Routes = ({ match }) => (
  <div  style={{paddingLeft: "280px"}}>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AssessmentEmailUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AssessmentEmailUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AssessmentEmailDetail} />
      <ErrorBoundaryRoute path={match.url} component={AssessmentEmail} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AssessmentEmailDeleteDialog} />
  </div>
);

export default Routes;
