import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Training from './training';
import TrainingDetail from './training-detail';
import TrainingStepper from './training-stepper';
import TrainingDeleteDialog from './training-delete-dialog';
import AdminTrainingView from "app/entities/training/admin-training-view";
import ApplyTrainingSchedule from "app/entities/training/apply-training-schedule";
import ApplyTrainingSession from "app/entities/training/apply-training-session";
import ApplyTrainingDates from "app/entities/training/apply-training-dates";
import EnrollTraining from "app/entities/training/enroll-training";
import WatchTraining from 'app/entities/training/watch-training';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TrainingStepper} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TrainingStepper} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AdminTrainingView} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/detail`} component={TrainingDetail} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/watch`} component={WatchTraining} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/enroll`} component={EnrollTraining} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/schedule`} component={ApplyTrainingSchedule} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/session`} component={ApplyTrainingSession} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/dates`} component={ApplyTrainingDates} />
      <ErrorBoundaryRoute path={match.url} component={Training} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={TrainingDeleteDialog} />
  </>
);

export default Routes;
