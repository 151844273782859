import '../assessment.scss';
import React from 'react';
import {Button, Row} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface CoreTechnologyProps{
    selectedDifficulty: string;

    onDifficultyChange:Function;
    previousStep:Function;
    nextStep: Function;

}

interface CoreTechnologyState{
    errors:string[];
}

export class DifficultySelection extends React.Component<CoreTechnologyProps,CoreTechnologyState> {

    constructor(props){
        super(props);
        this.state = {errors:[]};
    }

    validate():void{
        this.setState({errors:[]}); // clear existing errors
        const currentErrors = [];

        console.log(this.props.selectedDifficulty)
        if(!this.props.selectedDifficulty){
            currentErrors.push('You need to select a difficulty level');
        }

        this.setState({errors:currentErrors});
        if(currentErrors.length === 0){
            this.props.nextStep();
        }
    }

    render() {
        return (
            <div>
                <div className="page-title">
                    <h4>
                        <span><b>Select Question Difficulty</b></span>
                    </h4>
                    <p className="label-secondary">Choose based on experience levels.</p>
                </div>

                {this.state.errors ? (<label style={{color: "red"}}>{
                    this.state.errors.map(error=><div key={error}><span>{error}</span><br/></div>)}</label>) : (<span></span>)}


                <AvGroup>
                    <Row>
                        <div className="pretty p-default p-round p-thick">
                            <input type="radio" value="Easy"
                                   defaultChecked={this.props.selectedDifficulty && this.props.selectedDifficulty=== 'Easy'}
                                   name="color"
                                   onChange={(event) => this.props.onDifficultyChange(event,'Easy')}/>
                            <div className="state p-primary-o">
                                <label><b>Easy</b></label>
                                <div className="technology-description">
                                    <span className="label-secondary">For fresh developers.</span>
                                </div>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        <div className="pretty p-default p-round p-thick">
                            <input type="radio" value="Medium"
                                   defaultChecked={this.props.selectedDifficulty && this.props.selectedDifficulty=== 'Medium'}
                                   name="color"
                                   onChange={(event) => this.props.onDifficultyChange(event,'Medium')}/>
                            <div className="state p-primary-o">
                                <label><b>Medium</b></label>
                                <div className="technology-description">
                                    <span className="label-secondary">For developers with 1-3 years of experience.</span>
                                </div>
                            </div>
                        </div>
                    </Row>

                    <Row>
                        <div className="pretty p-default p-round p-thick">
                            <input type="radio" value="Hard"
                                   defaultChecked={this.props.selectedDifficulty && this.props.selectedDifficulty=== 'Hard'}
                                   name="color"
                                   onChange={(event) => this.props.onDifficultyChange(event,'Hard')}/>
                            <div className="state p-primary-o">
                                <label><b>Hard</b></label>
                                <div className="technology-description">
                                    <span className="label-secondary">For developers with more than 3 years of experience</span>
                                </div>
                            </div>
                        </div>
                    </Row>


                    <AvFeedback>
                        <Translate contentKey="entity.validation.required">This field is
                            required.</Translate>
                    </AvFeedback>
                </AvGroup>

                <div className="marginTop marginBottom">
                    <Button onClick={this.props.previousStep} color="btn-primary"
                            className="btn-primary">
                        <FontAwesomeIcon icon="arrow-left"/>
                        &nbsp;
                        <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
                    </Button>
                    <Button onClick={()=>this.validate()} color="info"
                            className="pull-right btn-primary">
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.next"></Translate>
                          </span>&nbsp;
                          <FontAwesomeIcon icon="arrow-right"/>
                    </Button>
                </div>
            </div>
        )
    }
}
