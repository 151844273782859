export interface IAudit {
  id?: string;
  name?: string;
  description?: string;
  isCore?: boolean;
  core?: boolean;
  totalQuestions?: number;
  totalEnabledQuestions?: number;
  totalEasyQuestions?: number;
  totalMediumQuestions?: number;
  totalHardQuestions?: number;
  selectedQuestionCount?: number;
}

export const defaultValue: Readonly<IAudit> = {
  isCore: false,
  core: false
};

export interface Auditable {
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
}
