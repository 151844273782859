import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {connect} from 'react-redux';
import {getSortState, IPaginationBaseState, JhiItemCount, JhiPagination} from 'react-jhipster';
import {Card, CardActions, CardContent, InputAdornment, OutlinedInput} from "@material-ui/core"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {RouteComponentProps} from 'react-router-dom';
import {IRootState} from 'app/shared/reducers';
import {getSession} from 'app/shared/reducers/authentication';
import {getEntities, searchEntities} from 'app/entities/assessment/assessment.reducer';
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";


export interface IAssessmentListProps extends StateProps, DispatchProps, RouteComponentProps <{ url: string }> {

}

export interface IAssessmentListState extends IPaginationBaseState {
  searchField: string

}

export class AssessmentList extends React.Component<IAssessmentListProps, IAssessmentListState> {
  state: IAssessmentListState = {
      ...getSortState(this.props.location, ITEMS_PER_PAGE),
      searchField: ""

    };

  componentDidMount() {
    this.getEntities();
    this.onHandleSearchChange = this.onHandleSearchChange.bind(this)
    this._handleSearchKeyDown = this._handleSearchKeyDown.bind(this)
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  getEntities = () => {
    const {activePage, itemsPerPage, sort, order} = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  onHandleSearchChange(e) {
    this.setState({
      ...this.state,
      searchField: e.target.value
    })
  }

  _handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      if(this.state.searchField.length === 0) {
        this.getEntities()
      } else {
        this.props.searchEntities(this.state.searchField)
      }
    }
  }

  render() {
    const { searchField } = this.state
    const {assessmentList, totalItems} = this.props;
    return (
      <div style={{padding:50}}>
          <Row>
            <Col sm={12} style={{height: 80}}>
              <h4 className="pull-left" id="title-style">
                Assessments
              </h4>
            </Col>
            <h4   style={{marginLeft: 25}}>
              Other Assessments
            </h4>
            <OutlinedInput
                  className="px-2 py-2 pull-left"
                  placeholder="Search by Name"
                  id="input-with-icon-adornment"
                  value={searchField}
                  onChange={this.onHandleSearchChange}
                  onKeyDown={this._handleSearchKeyDown}
                  startAdornment={
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={"search"}/>
                    </InputAdornment>
                  }
                  style={{height:40,marginLeft:50, minWidth: 600, backgroundColor: '#ffffff'}}
              />
          </Row>


          {this.props.assessmentList && this.props.assessmentList.length > 0 ? (
            <Row className="mt-5">
              {this.props.assessmentList.map((assessment, i) => (
                <Col sm={4} key={`entity-${i}`}>
                    <Card style={{marginBottom:50, backgroundColor:"#ECEDEF"}}>
                      <CardContent className="px-0 py-0">

                        <Row className="px-2 py-2 pull-left w-100">
                          <h4>{assessment.title}</h4>
                        </Row>

                        <Row className="px-2 py-2 pull-left w-100">
                          <h6>{assessment.description}</h6>
                        </Row>

                      </CardContent>
                      <CardActions>
                        <Row style={{marginLeft:0,width:"100%"}}>
                          <Button variant="primary">
                            Apply
                          </Button>
                        </Row>
                      </CardActions>
                  </Card>
                </Col>
              ))}
            </Row>

          ) : (
            <Row >
              <span>No assessments found</span>
            </Row>
          )}

        <div className={this.props.assessmentList && this.props.assessmentList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems}
                          itemsPerPage={this.state.itemsPerPage} i18nEnabled/>
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={this.state.activePage}
              onSelect={this.handlePagination}
              maxButtons={5}
              itemsPerPage={this.state.itemsPerPage}
              totalItems={this.props.totalItems}
            />
          </Row>
        </div>

      </div>

    )
  }
};

const mapStateToProps = ({assessment}: IRootState) => ({
  assessmentList: assessment.entities,
  totalItems: assessment.totalItems,

});

const mapDispatchToProps = {getSession, getEntities, searchEntities};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentList);
