import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Card, Col, Row} from 'reactstrap';
import TextField from '@material-ui/core/TextField';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './training.reducer';
import {getTrainingScheduleDetails} from "app/entities/training-schedule/training-schedule.reducer";

export interface ITrainingDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ApplyTrainingSchedule extends React.Component<ITrainingDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
    const trainingId = this.props.match.params.id;
    this.props.getTrainingScheduleDetails(trainingId);

  }
  displayImage = (image) => {
    const imageUrl = "data:image/;base64," + image;
    return imageUrl;
  }
  render() {
    const { trainingEntity } = this.props;
    const { trainingScheduleEntity } = this.props;
    console.log("testtt",trainingScheduleEntity)




    return (
      <Card className="col-xs-10 col-lg-6">

        <Row className="justify-content-center">
        <Col md="8">
          <h2 style={{textAlign:'center'}}>
            Schedule Details
          </h2>
        </Col>
        </Row>


        <Row className="justify-content-center">
            <Col md="6">
              {trainingScheduleEntity.map((trainingSchedule, i) => (
              <Row key={`entity-${i}`} className="form-row">


                    <TextField id="assessment-title"

                               label="Title"
                               variant="filled"
                               fullWidth
                               name="name"
                               disabled
                               value={trainingSchedule.scheduleName}

                    />
              </Row>
              ))}




            </Col>


          </Row>
        <div style={{textAlign:'center'}}>


          <Button tag={Link} to={`/training/${trainingEntity.id}/detail`} color="info" size="sm">
                            <span className="d-none d-md-inline">
                             Back
                           </span>
          </Button>
          &nbsp;
            <Button tag={Link} to={`/training/${trainingEntity.id}/session`} color="info" size="sm">
                            <span className="d-none d-md-inline">
                             Next Page
                           </span>
            </Button>
          </div>

      </Card>
    );
  }
}

const mapStateToProps = ({ training, trainingSchedule }: IRootState) => ({
  trainingEntity: training.entity,
  trainingScheduleEntity: trainingSchedule.trainingScheduleList
});

const mapDispatchToProps = { getEntity, getTrainingScheduleDetails };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyTrainingSchedule);
