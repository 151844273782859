import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './candidate-assessment-experience.reducer';

export interface ICandidateAssessmentExperienceDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CandidateAssessmentExperienceDetail extends React.Component<ICandidateAssessmentExperienceDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { candidateAssessmentExperienceEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.detail.title">CandidateAssessmentExperience</Translate> [
            <b>{candidateAssessmentExperienceEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="year">
                <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.year">Year</Translate>
              </span>
            </dt>
            <dd>{candidateAssessmentExperienceEntity.year}</dd>
            <dt>
              <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.audit">Audit</Translate>
            </dt>
            <dd>{candidateAssessmentExperienceEntity.audit ? candidateAssessmentExperienceEntity.audit.id : ''}</dd>
            <dt>
              <Translate contentKey="evaluateyouApp.candidateAssessmentExperience.assessment">Assessment</Translate>
            </dt>
            <dd>{candidateAssessmentExperienceEntity.assessment ? candidateAssessmentExperienceEntity.assessment.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/candidate-assessment-experience" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/candidate-assessment-experience/${candidateAssessmentExperienceEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ candidateAssessmentExperience }: IRootState) => ({
  candidateAssessmentExperienceEntity: candidateAssessmentExperience.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateAssessmentExperienceDetail);
