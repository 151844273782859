import React from 'react';
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} className="d-flex" style={{marginTop:"10px", marginLeft:"-30px", width:"60px"}}>
    <DropdownToggle nav className="align-items-center" >
      <FontAwesomeIcon icon={props.icon} />
      <span>&nbsp;&nbsp;{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
