import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import {NavItem, NavLink} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavLink as Link} from 'react-router-dom';
import {Translate} from 'react-jhipster';

const accountMenuItemsAuthenticated = (
  <>
    <MenuItem icon="wrench" to="/account/settings">
      <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </MenuItem>
    <MenuItem icon="lock" to="/account/password">
      <Translate contentKey="global.menu.account.password">Password</Translate>
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
);

const accountMenuItemsAssessment = (
  <>
    <MenuItem icon="wrench" to="/account/settings">
      <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </MenuItem>
    <MenuItem icon="piggy-bank" to="/credit">
      <Translate contentKey="global.menu.account.credit">Credits</Translate>
    </MenuItem>
    <MenuItem icon="lock" to="/account/password">
      <Translate contentKey="global.menu.account.password">Password</Translate>
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
);

const accountMenuItems = (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login">
      <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </MenuItem>
    <MenuItem icon="sign-in-alt" to="/choose-role">
      <Translate contentKey="global.menu.account.register">Register</Translate>
    </MenuItem>
  </>
);

export const SettingsMenu = ({ isAuthenticated = false, isHirer = false, creditVal = 0 }) => (


    <NavItem className="side-nav">
        <NavLink tag={Link} to="/account/settings" className="d-flex align-items-center">
            <FontAwesomeIcon icon="wrench" />&nbsp;&nbsp;&nbsp;
            <span>
                <Translate contentKey="global.menu.account.settings">Settings</Translate>
            </span>
        </NavLink>
    </NavItem>
);

export const PaymentHistoryMenu = ({ isAuthenticated = false, isHirer = true, creditVal = 0 }) => (


    <NavItem className="side-nav">
        <NavLink tag={Link} to="/credit" className="d-flex align-items-center">
            <FontAwesomeIcon icon="piggy-bank" />&nbsp;&nbsp;&nbsp;
            <span>
                <Translate contentKey="global.menu.account.credit">Credits</Translate>
            </span>
        </NavLink>
    </NavItem>
);

export const LogoutMenu = ({ isAuthenticated = false, isHirer = false, creditVal = 0 }) => (


    <NavItem className="side-nav">
        <NavLink tag={Link} to="/logout" className="d-flex align-items-center">
            <FontAwesomeIcon icon="sign-out-alt" />&nbsp;&nbsp;&nbsp;
            <span>
                <Translate contentKey="global.menu.account.logout">Logout</Translate>
            </span>
        </NavLink>
    </NavItem>
);

export default SettingsMenu;
