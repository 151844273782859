import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import {DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavDropdown} from './menu-components';
import {Translate, translate} from 'react-jhipster';

const adminMenuItems = (
    <div>
        <MenuItem icon="universal-access" to="/payment-request">
            <Translate contentKey="global.menu.admin.paymentRequest"></Translate>
        </MenuItem>
        <MenuItem icon="barcode" to="/coupon">
            <Translate contentKey="global.menu.admin.coupons"></Translate>
        </MenuItem>
        <MenuItem icon="wrench" to="/admin-config">
            <Translate contentKey="global.menu.admin.config"></Translate>
        </MenuItem>
        <MenuItem icon="fire" to="/credit/topUp">
            <Translate contentKey="global.menu.admin.topUp"></Translate>
        </MenuItem>
        <MenuItem icon="tag" to="/tag">
            <Translate contentKey="evaluateyouApp.tag.home.title"></Translate>
        </MenuItem>
        <MenuItem icon="road" to="/admin/gateway">
            <Translate contentKey="global.menu.admin.gateway">Gateway</Translate>
        </MenuItem>
        <MenuItem icon="user" to="/admin/user-management">
            <Translate contentKey="global.menu.admin.userManagement">User management</Translate>
        </MenuItem>
        <MenuItem icon="eye" to="/admin/tracker">
            <Translate contentKey="global.menu.admin.tracker">User tracker</Translate>
        </MenuItem>
        <MenuItem icon="tachometer-alt" to="/admin/metrics">
            <Translate contentKey="global.menu.admin.metrics">Metrics</Translate>
        </MenuItem>
        <MenuItem icon="heart" to="/admin/health">
            <Translate contentKey="global.menu.admin.health">Health</Translate>
        </MenuItem>
        <MenuItem icon="list" to="/admin/configuration">
            <Translate contentKey="global.menu.admin.configuration">Configuration</Translate>
        </MenuItem>
        <MenuItem icon="bell" to="/admin/audits">
            <Translate contentKey="global.menu.admin.audits">Audits</Translate>
        </MenuItem>
        <MenuItem icon="list" to="/university">
            University
        </MenuItem>
        <MenuItem icon="list" to="/faculty">
            Faculty
        </MenuItem>
        <MenuItem icon="list" to="/course">
            Course
        </MenuItem>
        <MenuItem icon="list" to="/major">
            Major
        </MenuItem>
        <MenuItem icon="list" to="/profile-role">
            Profile Roles
        </MenuItem>
        {/* jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here */}
        <MenuItem icon="tasks" to="/admin/logs">
            <Translate contentKey="global.menu.admin.logs">Logs</Translate>
        </MenuItem>
    </div>
);

const swaggerItem = (
    <MenuItem icon="book" to="/admin/docs">
        <Translate contentKey="global.menu.admin.apidocs">API</Translate>
    </MenuItem>
);

const databaseItem = (
    <DropdownItem tag="a" href="./h2-console" target="_tab">
        <FontAwesomeIcon icon="hdd" fixedWidth/> <Translate
        contentKey="global.menu.admin.database">Database</Translate>
    </DropdownItem>
);

export const AdminMenu = ({showSwagger, showDatabase}) => (
    <NavDropdown icon="user-plus" name={translate('global.menu.admin.main')}
                 style={{width: '140%', paddingLeft: 30, marginLeft: 30}} id="admin-menu">
        {adminMenuItems}
        {showSwagger && swaggerItem}

        {showDatabase && databaseItem}
    </NavDropdown>
);

export default AdminMenu;
