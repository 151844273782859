// @flow
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IUser} from 'app/shared/model/user.model';

type Props = {
  account: IUser
  hideName?: boolean
};
export const ProfileImage = (props: Props) => {
  return (
    <React.Fragment>
      <div className="img-circle-profile mt-4">
        {
          props.account.imageUrl ?
            <img src={props.account.imageUrl} alt="profile"
                 className="img-circle-profile"/>
            :
            <FontAwesomeIcon className="profile-image mt-4" icon="user"
                             style={{height: 60, width: 60}}/>
        }

      </div>
      <div className="mt-2">
        {
          props.hideName ? null :props.account.login
        }
      </div>
    </React.Fragment>
  );
};
