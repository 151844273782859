import {IUser} from 'app/shared/model/user.model';

export interface IWorkExperience {
  id?: number;
  companyName?: string;
  role?: string;
  dateFrom?: Date;
  dateTo?: Date;
  user?: IUser;
  description?: string;
}

export const defaultValue: Readonly<IWorkExperience> = {};
