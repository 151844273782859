import 'react-circular-progressbar/dist/styles.css';

import React from 'react';
import {Row} from 'reactstrap';
import {Button, Card, CardActions, CardContent, Typography} from "@material-ui/core";
import {CircularProgressbar} from 'react-circular-progressbar';
import {useHistory} from 'react-router-dom';

export interface ProfileCompletionProps {
    profileCompletion: number;

}

export const ProfileCompletion = (props:ProfileCompletionProps) =>{

    const history = useHistory();


    return (
        <Card className="w-100">
            <CardContent>
                <Typography>
                    <b>PROFILE</b>
                </Typography>
                {
                    props.profileCompletion === 100?
                        <Typography variant="subtitle1" style={{display: 'inline-block'}}>
                            Looking Good!
                        </Typography>
                        :
                        <Typography variant="subtitle1" style={{display: 'inline-block'}}>
                            Do complete your profile so that we can server you better!
                        </Typography>
                }
                <Row className="justify-content-center mt-2 w-100" >

                    <div style={{ width: 100, height: 100 }}>
                        <CircularProgressbar value={props.profileCompletion} text={`${props.profileCompletion}%`} />
                    </div>

                </Row>
            </CardContent>
            <CardActions>
                <Button onClick={()=>history.push("/account/profile")}>
                    <span className="d-none d-md-inline">Edit Profile</span>
                </Button>
            </CardActions>
        </Card>
    );

}
