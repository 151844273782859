import '../assessment.scss';
import React from 'react';
import {Button} from 'reactstrap';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAudit} from 'app/shared/model/audit.model';

interface TechnologySelectionProps {
    audits: any;
    selectedAudits: IAudit[];
    isCustom: boolean;
    difficulty:string;

    onAuditChange:Function;
    previousStep:Function;
    nextStep: Function;

}

interface TechnologySelectionState {
    errors:string[];
}

export class TechnologySelection extends React.Component<TechnologySelectionProps,TechnologySelectionState> {

    constructor(props) {
        super(props);
        this.state = {
            errors:[]
        }
    }

    render() {
        return (
            <div style={{height: '100%'}}>
                <div className="page-title">
                    <h4>
                        <span><b><Translate
                            contentKey="evaluateyouApp.assessment.steps.step3">Title</Translate></b></span>
                    </h4>

                    <p className="label-secondary">These are knowledge or tech skills that are important for the candidate to possess</p>
                    <p className="label-secondary"><i>(You will be prompted to select more categories if the total questions for the assessment is below 30)</i></p>

                </div>
                {this.state.errors ? (<label style={{color: "red"}}>{
                    this.state.errors.map(error=><div key={error}><span>{error}</span><br/></div>)}</label>) : (<span></span>)}

                <AvGroup>
                    <div className="two-col-overflow col-md-10">
                        {this.props.audits ? this.props.audits.map((audit, index) => (

                            <div className="pretty p-default p-curve p-thick two-col-item"
                                 key={index} style={{width: '50%'}}>
                                <input type="checkbox" value={audit.id}
                                       defaultChecked={this.isAuditSelected(audit.id)}
                                       onChange={(event) => this.props.onAuditChange(event,audit)}/>
                                <div className="state p-primary-o">
                                    <label><b>{audit.name}</b></label>
                                    <div className="technology-description">
                                        <span><i>{audit.description}</i></span>
                                    </div>
                                </div>
                            </div>

                        )) : <div>No secondary question categories found</div>}
                    </div>
                    <AvFeedback>
                        <Translate contentKey="entity.validation.required">This field is
                            required.</Translate>
                    </AvFeedback>
                </AvGroup>
                <div className="marginTop marginBottom">
                    <Button onClick={() => this.props.previousStep()} color="info" className="btn-primary">
                        <FontAwesomeIcon icon="arrow-left"/>
                        &nbsp;
                        <span className="btn-primary">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
                    </Button>
                    <Button onClick={() => this.validate()} color="info"
                            className="pull-right btn-primary">
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.next"></Translate>
                          </span>&nbsp;
                        <FontAwesomeIcon icon="arrow-right"/>
                    </Button>
                </div>
            </div>
        )
    }

    isAuditSelected(auditId: string):boolean{
        const selectedAudits = this.props.selectedAudits;
        const foundAudit = selectedAudits.find(existingAudit=>existingAudit.id === auditId);
        return foundAudit?true:false;
    }

    validate():void{
        this.setState({errors:[]}); // clear existing errors
        const currentErrors = [];


        if(this.props.selectedAudits.length === 0){
            currentErrors.push('At least 1 Technology is required');
        }

        // check if total questions are at least 30 based on difficulty and categories selected
        let totalQuestions = 0;
        this.props.selectedAudits.forEach(selectedAudit=>{

            switch(this.props.difficulty){
                case 'Easy':
                    totalQuestions += selectedAudit.totalEasyQuestions;
                    break;
                case 'Medium':
                    totalQuestions += selectedAudit.totalEasyQuestions;
                    totalQuestions += selectedAudit.totalMediumQuestions;
                    break;
                case 'Hard':
                    totalQuestions += selectedAudit.totalEasyQuestions;
                    totalQuestions += selectedAudit.totalMediumQuestions;
                    totalQuestions += selectedAudit.totalHardQuestions;
                    break;
                default:
                    totalQuestions = 0;
            }

        })

        if(totalQuestions < 30){
            currentErrors.push('Select more technologies as the current questions for the asessment is below 30');
        }
        this.setState({errors:currentErrors});


        if(currentErrors.length === 0){
            this.props.nextStep();
        }
    }
}
