import React, {useEffect, useState} from 'react';
import {Label, Row} from 'reactstrap';
import {Button, Card, CardActions, CardContent, Typography} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import {IUserTraining} from 'app/shared/model/user-training.model';
import {APP_DATE_FORMAT} from 'app/config/constants';
import {TextFormat} from 'react-jhipster';


export const UpcomingTrainingDates = (props: {userTrainings:IUserTraining[]}) =>{

    const {userTrainings} = props;
    const [inProgressTrainings, setInProgressTrainings] = useState<IUserTraining[]>([]);

    const history = useHistory();

    useEffect(()=>{

        console.log('use effect fired');

        const today = new Date();

        // count number of unique trainings
        const uniqueTrainingsIds = userTrainings.map(ut=>ut.training.id).filter((ut, i, a) => a.indexOf(ut) === i);

        let inprogressDates = [];
        uniqueTrainingsIds.forEach(t=>{
            const trainingDates = userTrainings.filter(ut=>ut.training.id === t);
            const activeTrainingDates = trainingDates.filter(ut=>new Date(ut.trainingDate.endDateTime) > today);
            inprogressDates = [...activeTrainingDates];

        })
        setInProgressTrainings(inprogressDates);

    },[userTrainings])


    return (
        <Card className="w-100">

            <CardContent>
                <Typography>
                    <b>UPCOMING TRAINING</b>
                </Typography>
                {
                    inProgressTrainings.length > 0 ?
                        <Typography variant="subtitle1" style={{display: 'inline-block'}}>
                            There are <b>{inProgressTrainings.length}</b> dates that are upcoming
                        </Typography>
                        :
                        <Typography variant="subtitle1" style={{display: 'inline-block'}}>
                            No upcoming training dates yet.
                        </Typography>
                }
                <Row className="justify-content-center mt-4" >
                    {
                        inProgressTrainings.length > 0 && inProgressTrainings.map((ut)=>{
                            return(
                                <Row key={ut.id}>
                                    <div>{ut.training.name},</div>
                                    <div className="ml-2">{ut.trainingDate.trainingSession.sessionName}</div>
                                    <Label>
                                        <TextFormat type="date" value={new Date(ut.trainingDate.startDateTime)} format={APP_DATE_FORMAT}/>
                                    </Label>
                                </Row>
                            );
                        })
                    }
                </Row>
            </CardContent>
            <CardActions>
                {
                    inProgressTrainings.length === 0 &&

                        <Button onClick={()=>history.push("/training-list")}>
                            <span className="d-none d-md-inline">Get Trained</span>
                        </Button>
                }

            </CardActions>
        </Card>
    );

}
