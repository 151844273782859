import '../../assessment/assessment.scss';
import React from 'react';
import {Button, Col, Row, Table} from 'reactstrap';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IResume} from "app/shared/model/resume.model";

interface ResumeConfirmationProps{
  model: IResume,
  previousStep:Function;
  submit: Function;
}

export class ResumeConfirmation extends React.Component<ResumeConfirmationProps> {
  constructor(props){
    super(props);
  }

  render() {
    return (
      <div>
        <div className="page-title">
          <h4>
            <span><b>Confirm your resume details</b></span>
          </h4>
          <h6>
            Check that all is good before we proceed to create your resume.
          </h6>
        </div>
        <div>
            <Row>
              <Col md="12">
                <div className="table-responsive">
                  {this.props.model.educations && this.props.model.educations.length > 0 ? (
                  <Table responsive aria-describedby="training-heading">
                    <thead>
                    <tr>
                      <h4><b>Education</b></h4>
                    </tr>
                    <tr>
                      <th className="hand" >
                        <Translate contentKey="evaluateyouApp.education.level">Level</Translate>
                      </th>
                      <th className="hand" >
                        <Translate contentKey="evaluateyouApp.education.yearFrom">Year From</Translate>
                      </th>
                      <th className="hand">
                        <Translate contentKey="evaluateyouApp.education.yearTo">Year To</Translate>
                      </th>
                      <th className="hand">
                        <Translate contentKey="evaluateyouApp.education.institution">Institution</Translate>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.model.educations.map((education, i) => (
                      <tr key={`entity-${i}`}>
                        <td>{education.level}</td>
                        <td>{education.yearFrom}</td>
                        <td>{education.yearTo}</td>
                        <td>{education.institution}</td>

                      </tr>
                    ))}
                    </tbody>
                  </Table>
                  ) : (
                    <div className="alert alert-warning">
                      <span>No Education Found</span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <Row>
            <Col md="12">
              <div className="table-responsive">
                {this.props.model.workExperiences && this.props.model.workExperiences.length > 0 ? (
                  <Table responsive aria-describedby="training-heading">
                    <thead>
                    <tr>
                      <h4><b>Work Experience</b></h4>
                    </tr>
                    <tr>
                      <th className="hand" >
                        <Translate contentKey="evaluateyouApp.workExperience.companyName">Company Name</Translate>
                      </th>
                      <th className="hand" >
                        <Translate contentKey="evaluateyouApp.workExperience.role">Role</Translate>
                      </th>
                      <th className="hand">
                        <Translate contentKey="evaluateyouApp.workExperience.dateFrom">Date From</Translate>
                      </th>
                      <th className="hand">
                        <Translate contentKey="evaluateyouApp.workExperience.dateTo">Date To</Translate>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.model.workExperiences.map((workExperience, i) => (
                      <tr key={`entity-${i}`}>
                        <td>{workExperience.companyName}</td>
                        <td>{workExperience.role}</td>
                        <td>{workExperience.dateFrom.toDateString()}</td>
                        <td>{workExperience.dateTo.toDateString()}</td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="alert alert-warning">
                    <span>No Work Experience Found</span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div className="marginTop marginBottom">
          <Button onClick={() => this.props.previousStep()} color="info" className="btn-primary">
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
          </Button>&nbsp;
          <Button className="pull-right" color="primary" id="save-entity" type="submit" onClick={()=>this.props.submit()}>
            <FontAwesomeIcon icon="save" />
            &nbsp;
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button>
        </div>
      </div>

    )
  }
}
