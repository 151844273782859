import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {byteSize, openFile, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './question-file.reducer';

export interface IQuestionFileDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class QuestionFileDetail extends React.Component<IQuestionFileDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { questionFileEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.questionFile.detail.title">QuestionFile</Translate> [<b>{questionFileEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="name">
                <Translate contentKey="evaluateyouApp.questionFile.name">Name</Translate>
              </span>
            </dt>
            <dd>{questionFileEntity.name}</dd>
            <dt>
              <span id="order">
                <Translate contentKey="evaluateyouApp.questionFile.order">Order</Translate>
              </span>
            </dt>
            <dd>{questionFileEntity.order}</dd>
            <dt>
              <span id="data">
                <Translate contentKey="evaluateyouApp.questionFile.data">Data</Translate>
              </span>
            </dt>
            <dd>
              {questionFileEntity.data ? (
                <div>
                  <a onClick={openFile(questionFileEntity.dataContentType, questionFileEntity.data)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                  <span>
                    {questionFileEntity.dataContentType}, {byteSize(questionFileEntity.data)}
                  </span>
                </div>
              ) : null}
            </dd>
            <dt>
              <Translate contentKey="evaluateyouApp.questionFile.auditQuestion">Audit Question</Translate>
            </dt>
            <dd>{questionFileEntity.auditQuestion ? questionFileEntity.auditQuestion.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/question-file" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/question-file/${questionFileEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ questionFile }: IRootState) => ({
  questionFileEntity: questionFile.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionFileDetail);
