import React from 'react';
import '../../assessment/assessment.scss';
import {Button, Col, Row, Table} from 'reactstrap';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import {IWorkExperience} from "app/shared/model/work-experience.model";
import {IResume} from "app/shared/model/resume.model";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {isBlank} from "app/shared/util/common-utils";


interface WorkExperienceTypeProps {


  model: IResume
  workExperienceCallback: Function;
  previousStep: Function;
  nextStep: Function;


}

interface WorkExperienceTypeState {
  errors: string[];
  userId: number;
  error: boolean,
  errorMessage: any,
  workExperiences: IWorkExperience[];
  workExperienceForm: IWorkExperience;
  addMode: boolean;

}

export class ResumeWorkExperience extends React.Component<WorkExperienceTypeProps, WorkExperienceTypeState> {

  constructor(props) {
    super(props);
    console.log('recreating state');
    this.state = {
      error: false,
      errorMessage: {},
      errors: [],
      userId: 0,
      workExperiences: this.props.model.workExperiences,
      workExperienceForm: {
        dateFrom: new Date(),
        dateTo: new Date()
      },
      addMode: true
    };
  }

  componentDidMount() {
    // call api or anything
    if (this.props.model.workExperiences) {
      this.setState({...this.state, workExperiences: this.props.model.workExperiences});
    }

  }

  validate(): void {
    // populate selected audits
    this.props.workExperienceCallback(this.state.workExperiences);
    this.props.nextStep();

  }

  goBack() {
    // populate selected audits
    this.props.workExperienceCallback(this.state.workExperiences);
    this.props.previousStep()
  }

  addOrUpdateWorkExperience(): void {
    console.log('adding session');
    // get the user
    if (isBlank(this.state.workExperienceForm.companyName && this.state.workExperienceForm.role && this.state.workExperienceForm.dateFrom && this.state.workExperienceForm.dateTo)) {
      this.setState({
        error: true,
        errorMessage: {
          companyName: "Name of institution is required",
          role: "Level of education is required",
          dateFrom: "Start year is required",
          dateTo: "End year is required"
        }
      });
    } else if (this.state.addMode) {
      this.setState({
        workExperiences: [...this.state.workExperiences,
          {
            ...this.state.workExperienceForm,
            id: (this.state.workExperiences.length + 1),
            user: {id: this.state.userId },
          }
        ]
      });
    } else {
      this.state.workExperiences.forEach(we => {
        if (we.id === this.state.workExperienceForm.id) {
          we.companyName = this.state.workExperienceForm.companyName;
          we.role = this.state.workExperienceForm.role;
          we.dateFrom = this.state.workExperienceForm.dateFrom;
          we.dateTo = this.state.workExperienceForm.dateTo;
          we.user.id = this.state.workExperienceForm.user.id;

        }
      });

      this.setState({workExperiences: [...this.state.workExperiences], error: false});
    }


    this.setState({workExperienceForm: {dateFrom:new Date(),dateTo: new Date()}, addMode: true, error: false});
  }

  editWorkExperience(id: number): void {
    console.log('editing education');
    this.setState({workExperienceForm: this.state.workExperiences.filter(e => e.id === id)[0], addMode: false});
  }

  deleteWorkExperience(idx: number): void {
    console.log('deleting education');
    this.state.workExperiences.splice(idx, 1);
    this.setState({workExperiences: [...this.state.workExperiences]});
  }


  render() {
    return (
      <div>
        <div className="page-title">
          <h4>
                        <span><b><Translate
                          contentKey="evaluateyouApp.workExperience.home.title">Title</Translate></b></span>
          </h4>
        </div>

        {this.state.errors ? (<label style={{color: "red"}}>{
          this.state.errors.map(error => <div key={error}><span>{error}</span><br/>
          </div>)}</label>) : (<span></span>)}

        {
          this.state.workExperiences.length > 0 &&

          <Row>
            <Col md={12}>
              <Table responsive aria-describedby="assessment-heading" id="hoverMenu">
                <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Role</th>
                  <th>Date From</th>
                  <th>Date To</th>
                  <th style={{width: 100, textAlign: 'left'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.workExperiences.map((workExperience, idx) => (
                    <tr key={workExperience.id}>
                      <td>{workExperience.companyName}</td>
                      <td>{workExperience.role}</td>
                      <td>{workExperience.dateFrom.toDateString()}</td>
                      <td>{workExperience.dateTo.toDateString()}</td>
                      <td style={{width: 100, textAlign: 'left'}}>
                        <div className="text-left">
                          <div className="btn-group flex-btn-group-container">
                            <Button className="mr-3"
                                    onClick={() => this.editWorkExperience(workExperience.id)}>
                              <FontAwesomeIcon icon="eye"/>{' '}<br/>
                              <span className="d-none d-md-inline">
                                              Edit
                                            </span>
                            </Button>

                            <Button onClick={() => this.deleteWorkExperience(idx)}>
                              <FontAwesomeIcon icon="trash"/>{' '}<br/>
                              <span className="d-none d-md-inline">
                                                  Delete
                                                </span>
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        }


        <Row className="form-row">
          <Col md={6}>
            <TextField id="assessment-title"
                       label="Company Name"
                       variant="filled"
                       fullWidth
                       name="companyName"
                       value={this.state.workExperienceForm.companyName ? this.state.workExperienceForm.companyName : ''}
                       onChange={(event) => this.setState({
                         workExperienceForm: {
                           ...this.state.workExperienceForm,
                           companyName: event.target.value
                         }
                       })}
                       error={this.state.errorMessage.companyName}
                       helperText="Name of the company."
                       required
            />
          </Col>
          <Col md={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                inputVariant="outlined"
                label="Start Time"
                showTodayButton={true}
                value={this.state.workExperienceForm.dateFrom ? this.state.workExperienceForm.dateFrom : new Date()}
                onChange={(m) => this.setState({
                  workExperienceForm: {
                    ...this.state.workExperienceForm,
                    dateFrom: m.toDate()
                  }
                })}
                onError={console.log}
                disablePast
                format="DD/MM/YYYY"
                error={this.state.errorMessage.dateFrom}
                helperText="Start Date"
              />
            </MuiPickersUtilsProvider>

          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextField id="assessment-title"
                       label="Role"
                       variant="filled"
                       fullWidth
                       name="role"
                       value={this.state.workExperienceForm.role ? this.state.workExperienceForm.role : ''}
                       onChange={(event) => this.setState({
                         workExperienceForm: {
                           ...this.state.workExperienceForm,
                           role: event.target.value
                         }
                       })}
                       error={this.state.errorMessage.role}
                       helperText="Role in the company."
                       required
            />
          </Col>

          <Col md={6}>

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                inputVariant="outlined"
                label="End Time"
                showTodayButton={true}
                value={this.state.workExperienceForm.dateTo ? this.state.workExperienceForm.dateTo : new Date()}
                onChange={(m) => this.setState({
                  workExperienceForm: {
                    ...this.state.workExperienceForm,
                    dateTo: m.toDate()
                  }
                })}
                onError={console.log}
                disablePast
                format="DD/MM/YYYY"
                error={this.state.errorMessage.dateTo}
                helperText="End Date"

              />
            </MuiPickersUtilsProvider>

          </Col>
        </Row>


        <Row className="mb-4">
          <Col md={12}>
            <Button onClick={() => this.addOrUpdateWorkExperience()} color="info"
                    className="btn-primary">
              <span className="d-none d-md-inline">{this.state.addMode ? 'Add' : 'Update'}</span>
            </Button>
          </Col>
        </Row>

        <div className="marginTop marginBottom">
          <Button onClick={() => this.goBack()} color="info"
                  className="btn-primary">
            <FontAwesomeIcon icon="arrow-left"/>
            &nbsp;
            <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
          </Button>
          <Button className="pull-right" color="primary" id="save-entity" type="submit"
                  onClick={() => this.validate()}>
            <Translate contentKey="entity.action.next">Save</Translate>&nbsp;
            <FontAwesomeIcon icon="arrow-right"/>
          </Button>
        </div>
      </div>
    );
  }

}
