import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './audit-question-option.reducer';

export interface IAuditQuestionOptionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AuditQuestionOptionDetail extends React.Component<IAuditQuestionOptionDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { auditQuestionOptionEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.auditQuestionOption.detail.title">AuditQuestionOption</Translate> [
            <b>{auditQuestionOptionEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="title">
                <Translate contentKey="evaluateyouApp.auditQuestionOption.title">Title</Translate>
              </span>
            </dt>
            <dd>{auditQuestionOptionEntity.title}</dd>
            <dt>
              <span id="score">
                <Translate contentKey="evaluateyouApp.auditQuestionOption.score">Score</Translate>
              </span>
            </dt>
            <dd>{auditQuestionOptionEntity.score}</dd>
            <dt>
              <span id="order">
                <Translate contentKey="evaluateyouApp.auditQuestionOption.order">Order</Translate>
              </span>
            </dt>
            <dd>{auditQuestionOptionEntity.order}</dd>
            <dt>
              <span id="isHighlight">
                <Translate contentKey="evaluateyouApp.auditQuestionOption.isHighlight">Is Highlight</Translate>
              </span>
            </dt>
            <dd>{auditQuestionOptionEntity.isHighlight ? 'true' : 'false'}</dd>
            <dt>
              <Translate contentKey="evaluateyouApp.auditQuestionOption.auditQuestion">Audit Question</Translate>
            </dt>
            <dd>{auditQuestionOptionEntity.auditQuestion ? auditQuestionOptionEntity.auditQuestion.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/audit-question-option" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/audit-question-option/${auditQuestionOptionEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ auditQuestionOption }: IRootState) => ({
  auditQuestionOptionEntity: auditQuestionOption.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditQuestionOptionDetail);
