import React from 'react';
import {Translate, translate} from 'react-jhipster';
import {connect} from 'react-redux';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {Button, Card, Col, Row} from 'reactstrap';
import {handlePasswordResetInit, reset} from '../password-reset.reducer';

export type IPasswordResetInitProps = DispatchProps;

export class PasswordResetInit extends React.Component<IPasswordResetInitProps> {
  componentWillUnmount() {
    this.props.reset();
  }

  handleValidSubmit = (event, values) => {
    this.props.handlePasswordResetInit(values.email);
    event.preventDefault();
  };

  render() {
    return (
        <Row className={'w-100 justify-content-center'}>
          <Col md="10" xs="12">
            <Card className="col-xs-10 col-lg-6"  style={{marginTop: 80,height:300}}>
              <Row className="justify-content-center">
                <Col md="8">
                  <Row style={{textAlign:'center', marginTop:20}}>
                    <Col md="12">
                      <h2 style={{fontSize:25}}>
                        <Translate contentKey="reset.request.title"></Translate>
                      </h2>
                    </Col>
                  </Row>

                  <AvForm onValidSubmit={this.handleValidSubmit}>
                    <AvField
                        name="email"
                        label={translate('global.form.email.label')}
                        placeholder={translate('global.form.email.placeholder')}
                        type="email"
                        validate={{
                          required: { value: true, errorMessage: translate('global.messages.validate.email.required') },
                          minLength: { value: 5, errorMessage: translate('global.messages.validate.email.minlength') },
                          maxLength: { value: 254, errorMessage: translate('global.messages.validate.email.maxlength') }
                        }}
                    />
                    <div style={{textAlign:'center'}}>
                      <Button color="primary" type="submit">
                        <Translate contentKey="reset.request.form.button">Reset password</Translate>
                      </Button>
                    </div>
                  </AvForm>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

    );
  }
}

const mapDispatchToProps = { handlePasswordResetInit, reset };

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  null,
  mapDispatchToProps
)(PasswordResetInit);
