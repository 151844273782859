import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Translate, translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';

import {reset} from './credit.reducer';
import CreditService from "app/shared/service/credit.service";
import {getUrlVars} from "app/shared/util/common-utils";

export interface ICreditPaymentProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICreditPaymentState {
  isNew: boolean;
}

export class PaymentComponent extends React.Component<ICreditPaymentProps, ICreditPaymentState> {
  creditService: CreditService;
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id
    };
    this.creditService = new CreditService();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    const urlVars = getUrlVars();
    const paymentId = urlVars['paymentId'];
    const PayerID = urlVars['PayerID'];
    // const token = urlVars['token'];
    this.creditService.completePayment({paymentId: paymentId, payerID: PayerID}).then(
      response =>{
        console.log(response);
        this.handleClose();
      }
    )
  }


  handleClose = () => {
    this.props.history.push('/credit');
  };

  render() {
    const { creditEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="evaluateyouApp.credit.home.createOrEditLabel">
              <Translate contentKey="evaluateyouApp.credit.home.createOrEditLabel">Create or edit a Credit</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : creditEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="credit-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="credit-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="creditLabel" for="credit-credit">
                    <Translate contentKey="evaluateyouApp.credit.credit">Credit</Translate>
                  </Label>
                  <AvField
                    id="credit-credit"
                    type="string"
                    className="form-control"
                    name="credit"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="spentCreditLabel" for="credit-spentCredit">
                    <Translate contentKey="evaluateyouApp.credit.spentCredit">Spent Credit</Translate>
                  </Label>
                  <AvField
                    id="credit-spentCredit"
                    type="string"
                    className="form-control"
                    name="spentCredit"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/credit" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  creditEntity: storeState.credit.entity,
  loading: storeState.credit.loading,
  updating: storeState.credit.updating,
  updateSuccess: storeState.credit.updateSuccess
});

const mapDispatchToProps = {
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentComponent);
