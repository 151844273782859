import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './credit-history.reducer';
import {APP_DATE_FORMAT} from 'app/config/constants';

export interface ICreditHistoryDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CreditHistoryDetail extends React.Component<ICreditHistoryDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { creditHistoryEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.creditHistory.detail.title">CreditHistory</Translate> [<b>{creditHistoryEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="amount">
                <Translate contentKey="evaluateyouApp.creditHistory.amount">Amount</Translate>
              </span>
            </dt>
            <dd>{creditHistoryEntity.amount}</dd>
            <dt>
              <span id="transactionId">
                <Translate contentKey="evaluateyouApp.creditHistory.transactionId">Transaction Id</Translate>
              </span>
            </dt>
            <dd>{creditHistoryEntity.transactionId}</dd>
            <dt>
              <span id="transactionTime">
                <Translate contentKey="evaluateyouApp.creditHistory.transactionTime">Transaction Time</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={creditHistoryEntity.transactionTime} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="status">
                <Translate contentKey="evaluateyouApp.creditHistory.status">Status</Translate>
              </span>
            </dt>
            <dd>{creditHistoryEntity.status}</dd>
            <dt>
              <span id="data">
                <Translate contentKey="evaluateyouApp.creditHistory.data">Data</Translate>
              </span>
            </dt>
            <dd>{creditHistoryEntity.data}</dd>
          </dl>
          <Button tag={Link} to="/credit-history" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/credit-history/${creditHistoryEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ creditHistory }: IRootState) => ({
  creditHistoryEntity: creditHistory.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditHistoryDetail);
