import '../../assessment/assessment.scss';
import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import "@pathofdev/react-tag-input/build/index.css";
import {TextFormat, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ITraining} from "app/shared/model/training.model";
import {ITrainingDate} from "app/shared/model/training-date.model";
import {ITrainingSchedule} from "app/shared/model/training-schedule.model";
import {APP_DATE_FORMAT} from 'app/config/constants';
import Button from '@material-ui/core/Button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css'
import {quillFormats, quillModules} from 'app/shared/util/ui-utils';


interface TrainingConfirmationProps {
  model: ITraining,

  previousStep: Function;
  submit: Function;
}

interface TrainingDateState {
  errors: string[];
  trainingDates: ITrainingDate[];
  trainingSchedules: ITrainingSchedule[];
  trainingScheduleId: number;
  trainingSessionId: number;
  addMode: boolean;
  error: boolean,
  errorMessage: any,

}

hljs.configure({
  languages: ['javascript', 'ruby', 'python', 'rust','java'],
});
hljs.highlightAll();



export class TrainingConfirmation extends React.Component<TrainingConfirmationProps, TrainingDateState> {
  constructor(props) {
    super(props);
    const dates = this.props.model.trainingSchedules.flatMap(ts => ts.sessions).flatMap(s => s.dates).filter(d => d !== undefined);

    this.state = {
      error: false,
      errorMessage: {},
      errors: [],
      trainingDates: dates.filter(d => d !== undefined).length > 0 ? dates : [],
      trainingSchedules: this.props.model.trainingSchedules,
      trainingScheduleId: 0,
      trainingSessionId: 0,
      addMode: true
    };

  }

  render() {
    return (
      <div>
        <div className="page-title">
          <h4>
            <span>Confirm your training details</span>
          </h4>
          <h6>
            Check that all is good before we proceed to create your training.
          </h6>
        </div>

        <Row className="mb-4">
            <Col md="12">
              <h4 className="pull-left" id="title-style">
                General Details
              </h4>
            </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div><b><Translate contentKey="evaluateyouApp.training.name">Title</Translate></b></div>
            <div className="assessment-detail-title">
              <span>{this.props.model.name}</span>
            </div>
          </Col>

          <Col md={4}>
            <div><b><Translate contentKey="evaluateyouApp.training.price">Price</Translate></b></div>
            <div className="assessment-detail-title">
              <span>{this.props.model.price}</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <div><b><Translate contentKey="evaluateyouApp.training.type">Type</Translate></b></div>
            <div className="assessment-detail-title">
              <span>{this.props.model.type}</span>
            </div>
          </Col>
          <Col md={4}>
            <div><b><Translate contentKey="evaluateyouApp.training.level">Level</Translate></b></div>
            <div className="assessment-detail-title">
              <span>{this.props.model.level}</span>
            </div>
          </Col>
          <Col md={4}>
            <div><b><Translate contentKey="evaluateyouApp.training.tags">Tags</Translate></b></div>
            <div className="assessment-detail-title">
              <span>{this.props.model.tags}</span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div><b><Translate contentKey="evaluateyouApp.training.description"/></b></div>
            <div className="assessment-detail-title">
              <ReactQuill
                  modules={quillModules}
                  formats={quillFormats} theme="bubble"
                  value={this.props.model.description}
                  readOnly={true}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div><b><Translate contentKey="evaluateyouApp.training.outcome"/></b></div>
            <div className="assessment-detail-title">
              <ReactQuill
                  modules={quillModules}
                  formats={quillFormats}
                  value={this.props.model.outcome}
                  readOnly={true}
                  theme={"bubble"}
              />
            </div>
          </Col>
        </Row>



        <Row>
          <Col md={6} xs={12}>
            <div><b>External Link</b></div>
            <div className="assessment-detail-title">
              <span>{this.props.model.externalLink}</span>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div><b>YouTube Video</b></div>
            <div className="assessment-detail-title">
              <span>{this.props.model.youTubeLink}</span>
            </div>
          </Col>
        </Row>


        <Row className="my-4">
          <Col md="12">
            <h4 className="pull-left" id="title-style">
              Schedules
            </h4>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Label>Schedules</Label>
          </Col>
          <Col md={4}>
            <Label>Sessions</Label>
          </Col>
          <Col md={4}>
            <Label>Dates</Label>
          </Col>
        </Row>


        {
          this.state.trainingSchedules.map( (trainingSchedule,idx) =>
              <Row key={idx} className="my-3">

                <Col md={4}>
                  {trainingSchedule.scheduleName}
                </Col>


                 <Col key={idx} md={8}>
                   {
                     trainingSchedule && trainingSchedule.sessions && trainingSchedule.sessions.map( (session,idx2) => {
                       return (<Row key={idx2} className="my-3">
                         <Col md={6}>{session.sessionName}</Col>
                            <Col md={6}>
                              {
                                session.dates.map((date, idx3) => {

                                return(<Row className="my-2" key={idx3}><TextFormat type="date" value={date.startDateTime} format={APP_DATE_FORMAT}/>&nbsp;&nbsp;-&nbsp;&nbsp;<TextFormat type="date" value={date.endDateTime} format={APP_DATE_FORMAT}/></Row>)
                                })
                              }
                            </Col>
                       </Row>);
                     })
                   }

                </Col>
              </Row>
          )
        }


        <div className="mt-5 mb-4">
          <Button onClick={() => this.props.previousStep()} variant="contained" color="primary">
            <FontAwesomeIcon icon="arrow-left"/>
            &nbsp;
            <span className="d-none d-md-inline">
                      <Translate contentKey="entity.action.prev">Back</Translate>
                    </span>
          </Button>&nbsp;
          <Button className="pull-right" variant="contained" color="primary" id="save-entity" onClick={() => this.props.submit()}>
            <FontAwesomeIcon icon="save"/>
            &nbsp;
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button>
        </div>
      </div>
    )
  }

  displayImage = (image) => {
    const imageUrl = "data:image/;base64," + image;
    return imageUrl;
  }
}


