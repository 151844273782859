import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CreditHistory from './credit-history';
import CreditHistoryDetail from './credit-history-detail';
import CreditHistoryUpdate from './credit-history-update';
import CreditHistoryDeleteDialog from './credit-history-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CreditHistoryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CreditHistoryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CreditHistoryDetail} />
      <ErrorBoundaryRoute path={match.url} component={CreditHistory} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CreditHistoryDeleteDialog} />
  </>
);

export default Routes;
