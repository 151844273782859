import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Card, Col, Row, Table} from 'reactstrap';
import {
  getSortState,
  IPaginationBaseState,
  JhiItemCount,
  JhiPagination,
  Translate
} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IRootState} from 'app/shared/reducers';
import {getAdminTrainings} from './training.reducer';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import Button from '@material-ui/core/Button';


export interface ITrainingProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ITrainingState = IPaginationBaseState;

export class Training extends React.Component<ITrainingProps, ITrainingState> {
  state: ITrainingState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
        {
          order: this.state.order === 'asc' ? 'desc' : 'asc',
          sort: prop
        },
        () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getAdminTrainings(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { trainingList, match, totalItems, loading } = this.props;
    return (
        <>
        {
          loading ?
              <div>loading</div>
              :
              <div>
                <Row>
                  <Col md="9">
                    <h4 className="pull-left" id="title-style">
                      Trainings
                    </h4>
                    <Card className="main-card" style={{width: '132%'}}>
                      <Row>
                        <Col md="12">
                          <div>
                            <h2 className="pull-right">
                              <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                                <FontAwesomeIcon icon="plus" />
                                &nbsp;
                                <Translate contentKey="evaluateyouApp.training.home.createLabel">Create a new Training</Translate>
                              </Link>
                            </h2>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="table-responsive">
                            {trainingList && trainingList.length > 0 ? (
                                <Table responsive aria-describedby="training-heading">
                                  <thead>
                                  <tr>
                                    <th className="hand" onClick={this.sort('name')}>
                                      <Translate contentKey="evaluateyouApp.training.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('type')}>
                                      <Translate contentKey="evaluateyouApp.training.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('price')}>
                                      <Translate contentKey="evaluateyouApp.training.price">Price</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('level')}>
                                      <Translate contentKey="evaluateyouApp.training.level">Level</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="hand" onClick={this.sort('tags')}>
                                      <Translate contentKey="evaluateyouApp.training.tags">Tags</Translate> <FontAwesomeIcon icon="sort" />
                                    </th>
                                    <th className="text-center">Actions</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {trainingList.map((training, i) => (
                                      <tr key={`entity-${i}`}>
                                        <td>{training.name}</td>
                                        <td>{training.type}</td>
                                        <td>{training.price}</td>
                                        <td>{training.level}</td>
                                        <td>{training.tags}</td>
                                        <td className="text-center">
                                          <div className="btn-group flex-btn-group-container">
                                            <Button onClick={()=>this.props.history.push(`${match.url}/${training.id}`)}>
                                     <span className="d-none d-md-inline">
                                      <Translate contentKey="entity.action.view">View</Translate>
                                    </span>
                                            </Button>
                                            <Button onClick={()=>this.props.history.push(`${match.url}/${training.id}/edit`)}>
                                  <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.edit">Edit</Translate>
                                </span>
                                            </Button>
                                            <Button onClick={()=>this.props.history.push(`${match.url}/${training.id}/delete`)}>
                                  <span className="d-none d-md-inline">
                                  <Translate contentKey="entity.action.delete">Delete</Translate>
                                </span>
                                            </Button>
                                          </div>
                                        </td>
                                      </tr>
                                  ))}
                                  </tbody>
                                </Table>
                            ) : (
                                <div className="alert alert-warning">
                                  <Translate contentKey="evaluateyouApp.training.home.notFound">No Trainings found</Translate>
                                </div>
                            )}
                          </div>
                          <div className={trainingList && trainingList.length > 0 ? '' : 'd-none'}>
                            <Row className="justify-content-center">
                              <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} i18nEnabled />
                            </Row>
                            <Row className="justify-content-center">
                              <JhiPagination
                                  activePage={this.state.activePage}
                                  onSelect={this.handlePagination}
                                  maxButtons={5}
                                  itemsPerPage={this.state.itemsPerPage}
                                  totalItems={this.props.totalItems}
                              />
                            </Row>
                          </div>
                        </Col>
                      </Row>

                    </Card>
                  </Col>

                </Row>
              </div>
        }
        </>

    );
  }
}

const mapStateToProps = ({ training }: IRootState) => ({
  trainingList: training.entities,
  loading: training.loading,
  totalItems: training.totalItems
});

const mapDispatchToProps = {
  getAdminTrainings
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Training);
