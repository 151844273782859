import axios from 'axios';

const apiUrl = 'api/audits';

export class AuditService {
  constructor() {}

  async getEntity(id) {
    const requestUrl = `${apiUrl}/${id}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async getDTOEntitiesByQuestionEnabled() {
    const requestUrl = `${apiUrl}/DTOByEnabled`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async getDTOEntitiesByUsed() {
    const requestUrl = `${apiUrl}/DTOByUsed`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async getDTOEntitiesByQuestionEnabled1() {
    const requestUrl = `${apiUrl}/DTOByEnabled`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async getDTOs(page, size, sort) {
    const requestUrl = `${apiUrl}/getDTOs${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios
      .get(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }

  async getDTOEntitiesByQuestionEnabledAndCore(isCore) {
    const requestUrl = `${apiUrl}/DTOByCoreAndEnabled?isCore=` + isCore;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async getDTOEntitiesByQuestionsEnabled() {
    const requestUrl = `${apiUrl}/DTOByEnabledQuestions?`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }
  async getByAssessment(assessmentId) {
    const requestUrl = `${apiUrl}/byAssessment?assessmentId=` + assessmentId;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async getAuditTags(id) {
    const requestUrl = `api/audit-tags/getByAudit/${id}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body.data;
      })
      .catch(error => {
        alert(error);
      });
  }

  async createTags(id) {
    const requestUrl = `api/audit-questions/createdTags/${id}`;
    return axios
      .get(requestUrl)
      .then(response => {
        return response;
      })
      .then(body => {
        return body;
      })
      .catch(error => {
        alert(error);
      });
  }
}

export default AuditService;
