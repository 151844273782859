import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './work-experience.reducer';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';

export interface IWorkExperienceDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class WorkExperienceDetail extends React.Component<IWorkExperienceDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { workExperienceEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.workExperience.detail.title">WorkExperience</Translate> [<b>{workExperienceEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="companyName">
                <Translate contentKey="evaluateyouApp.workExperience.companyName">Company Name</Translate>
              </span>
            </dt>
            <dd>{workExperienceEntity.companyName}</dd>
            <dt>
              <span id="role">
                <Translate contentKey="evaluateyouApp.workExperience.role">Role</Translate>
              </span>
            </dt>
            <dd>{workExperienceEntity.role}</dd>
            <dt>
              <span id="dateFrom">
                <Translate contentKey="evaluateyouApp.workExperience.dateFrom">Date From</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={workExperienceEntity.dateFrom} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
            <dt>
              <span id="dateTo">
                <Translate contentKey="evaluateyouApp.workExperience.dateTo">Date To</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={workExperienceEntity.dateTo} type="date" format={APP_LOCAL_DATE_FORMAT} />
            </dd>
          </dl>
          <Button tag={Link} to="/work-experience" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/work-experience/${workExperienceEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ workExperience }: IRootState) => ({
  workExperienceEntity: workExperience.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkExperienceDetail);
