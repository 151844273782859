import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntity} from './audit-question.reducer';

export interface IAuditQuestionDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AuditQuestionDetail extends React.Component<IAuditQuestionDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { auditQuestionEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="evaluateyouApp.auditQuestion.detail.title">AuditQuestion</Translate> [<b>{auditQuestionEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="title">
                <Translate contentKey="evaluateyouApp.auditQuestion.title">Title</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.title}</dd>
            <dt>
              <span id="code">
                <Translate contentKey="evaluateyouApp.auditQuestion.code">Code</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.code}</dd>
            <dt>
              <span id="type">
                <Translate contentKey="evaluateyouApp.auditQuestion.type">Type</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.type}</dd>
            <dt>
              <span id="score">
                <Translate contentKey="evaluateyouApp.auditQuestion.score">Score</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.score}</dd>
            <dt>
              <span id="totalWrong">
                <Translate contentKey="evaluateyouApp.auditQuestion.totalWrong">Total Wrong</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.totalWrong}</dd>
            <dt>
              <span id="totalCorrect">
                <Translate contentKey="evaluateyouApp.auditQuestion.totalCorrect">Total Correct</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.totalCorrect}</dd>
            <dt>
              <span id="order">
                <Translate contentKey="evaluateyouApp.auditQuestion.order">Order</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.order}</dd>
            <dt>
              <span id="enabled">
                <Translate contentKey="evaluateyouApp.auditQuestion.enabled">Enabled</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.enabled ? 'true' : 'false'}</dd>
            <dt>
              <span id="comment">
                <Translate contentKey="evaluateyouApp.auditQuestion.comment">Comment</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.comment}</dd>
            <dt>
              <span id="tags">
                <Translate contentKey="evaluateyouApp.auditQuestion.tags">Tags</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.tags}</dd>
            <dt>
              <span id="time">
                <Translate contentKey="evaluateyouApp.auditQuestion.time">Time</Translate>
              </span>
            </dt>
            <dd>{auditQuestionEntity.time}</dd>
            <dt>
              <Translate contentKey="evaluateyouApp.auditQuestion.audit">Audit</Translate>
            </dt>
            <dd>{auditQuestionEntity.audit ? auditQuestionEntity.audit.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/audit-question" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/audit-question/${auditQuestionEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ auditQuestion }: IRootState) => ({
  auditQuestionEntity: auditQuestion.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditQuestionDetail);
