import React from 'react';
import '../../assessment/assessment.scss';
import {Button, Col, Row, Table} from 'reactstrap';
import {Translate} from 'react-jhipster';
import "@pathofdev/react-tag-input/build/index.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';

import {IEducation} from "app/shared/model/education.model";
import {IResume} from 'app/shared/model/resume.model';
import {isBlank} from "app/shared/util/common-utils";

interface EducationTypeProps {

  model: IResume

  educationCallback: Function,
  previousStep: Function;
  nextStep: Function;
}

interface EducationTypeState {
  error: boolean;
  errorMessage: any;
  errors: string[];
  userId: number;
  educations: IEducation[];
  educationForm: IEducation;
  addMode: boolean;
}

export class ResumeEducation extends React.Component<EducationTypeProps, EducationTypeState> {

  constructor(props) {
    super(props);
    console.log('recreating state');
    this.state = {
      error: false,
      errorMessage: {},
      errors: [],
      userId: 0,
      educations: this.props.model.educations,
      educationForm: {},
      addMode: true
    };
  }

  componentDidMount() {
    // call api or anything
    if (this.props.model.educations) {
      this.setState({...this.state, educations: this.props.model.educations});
    }

  }

  validate(): void {
    // populate selected audits
    this.props.educationCallback(this.state.educations);
    this.props.nextStep();


  }


  addOrUpdateEducation(): void {
    console.log('adding session');

    if (isBlank(this.state.educationForm.institution && this.state.educationForm.level && this.state.educationForm.yearFrom && this.state.educationForm.yearTo)) {
      this.setState({
        error: true,
        errorMessage: {
          institution: "Name of institution is required",
          level: "Level of education is required",
          yearFrom: "Start year is required",
          yearTo: "End year is required"
        }
      });
    } else if (this.state.addMode) {
      this.setState({
        educations: [...this.state.educations,
          {
            ...this.state.educationForm,
            id: (this.state.educations.length + 1),
            user: { id: this.state.userId }
          }
        ]
      });
    } else {
      this.state.educations.forEach(e => {
        if (e.id === this.state.educationForm.id) {
          e.institution = this.state.educationForm.institution;
          e.level = this.state.educationForm.level;
          e.yearFrom = this.state.educationForm.yearFrom;
          e.yearTo = this.state.educationForm.yearTo;
          e.user.id = this.state.educationForm.user.id;

        }
      });

      this.setState({educations: [...this.state.educations], error: false});
    }


    this.setState({educationForm: {}, addMode: true, error: false});
  }


  editEducation(id: number): void {
    console.log('editing education');
    this.setState({educationForm: this.state.educations.filter(e => e.id === id)[0], addMode: false});
  }

  deleteEducation(idx: number): void {
    console.log('deleting education');
    this.state.educations.splice(idx, 1);
    this.setState({educations: [...this.state.educations]});
  }


  render() {


    return (
      <div>
        <div className="page-title">
          <h4>
            <span><b>Education</b></span>
          </h4>
        </div>

        {this.state.errors ? (<label style={{color: "red"}}>{
          this.state.errors.map(error => <div key={error}><span>{error}</span><br/>
          </div>)}</label>) : (<span></span>)}

        {
          this.state.educations.length > 0 &&

          <Row>
            <Col md={12}>
              <Table responsive aria-describedby="assessment-heading" id="hoverMenu">
                <thead>
                <tr>
                  <th>Institution</th>
                  <th>Level</th>
                  <th>Year From</th>
                  <th>Year To</th>
                  <th style={{width: 100, textAlign: 'left'}}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.educations.map((education, idx) => (
                    <tr key={education.id}>
                      <td>{education.institution}</td>
                      <td>{education.level}</td>
                      <td>{education.yearFrom}</td>
                      <td>{education.yearTo}</td>
                      <td style={{width: 100, textAlign: 'left'}}>
                        <div className="text-left">
                          <div className="btn-group flex-btn-group-container">
                            <Button className="mr-3"
                                    onClick={() => this.editEducation(education.id)}>
                              <FontAwesomeIcon icon="eye"/>{' '}<br/>
                              <span className="d-none d-md-inline">
                                              Edit
                                            </span>
                            </Button>

                            <Button onClick={() => this.deleteEducation(idx)}>
                              <FontAwesomeIcon icon="trash"/>{' '}<br/>
                              <span className="d-none d-md-inline">
                                                  Delete
                                                </span>
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        }


        <Row className="form-row">
          <Col md={6}>
            <TextField
              id="assessment-title"
              label="Institution"
              variant="filled"
              fullWidth
              name="institution"
              value={this.state.educationForm.institution ? this.state.educationForm.institution : ''}
              onChange={(event) => this.setState({
                educationForm: {
                  ...this.state.educationForm,
                  institution: event.target.value
                }
              })}
              error={this.state.errorMessage.institution}
              helperText="Please enter name of institution."

              required

            />
          </Col>
          <Col md={6}>
            <TextField id="assessment-title"
                       label="Level"
                       variant="filled"
                       fullWidth
                       name="level"
                       value={this.state.educationForm.level ? this.state.educationForm.level : ''}
                       onChange={(event) => this.setState({
                         educationForm: {
                           ...this.state.educationForm,
                           level: event.target.value

                         }
                       })}
                       error={this.state.errorMessage.level}
                       helperText="Level of education."

                       required
            />
          </Col>

        </Row>

        <Row className="form-row">
          {/* <Col md={6}> */}
          {/*   <TextField id="assessment-title" */}
          {/*              label="Year From" */}
          {/*              variant="filled" */}
          {/*              type="number" */}
          {/*              fullWidth */}
          {/*              name="yearFrom" */}
          {/*              value={this.state.educationForm.yearFrom ? this.state.educationForm.yearFrom : ''} */}
          {/*              onChange={(event) => this.setState({ */}
          {/*                educationForm: { */}
          {/*                  ...this.state.educationForm, */}
          {/*                  yearFrom: parseInt(event.target.value, 10) */}
          {/*                } */}
          {/*              })} */}
          {/*              error={this.state.errorMessage.yearFrom} */}
          {/*              helperText="Start year of education level." */}


          {/*   /> */}
          {/* </Col> */}
          {/* <Col md={6}> */}
          {/*   <TextField id="assessment-title" */}
          {/*              label="Year To" */}
          {/*              type="number" */}
          {/*              variant="filled" */}
          {/*              fullWidth */}
          {/*              name="yearTo" */}
          {/*              value={this.state.educationForm.yearTo ? this.state.educationForm.yearTo : ''} */}
          {/*              onChange={(event) => this.setState({ */}
          {/*                educationForm: { */}
          {/*                  ...this.state.educationForm, */}
          {/*                  yearTo: parseInt(event.target.value, 10) */}
          {/*                } */}
          {/*              })} */}
          {/*              error={this.state.errorMessage.yearTo} */}
          {/*              helperText="Last year of education level." */}
          {/*              required */}

          {/*   /> */}
          {/* </Col> */}
        </Row>
        <Row className="mb-4">
          <Col md={12}>
            <Button onClick={() => this.addOrUpdateEducation()} color="info"
                    className="btn-primary">
              <span className="d-none d-md-inline">{this.state.addMode ? 'Add' : 'Update'}</span>
            </Button>
          </Col>
        </Row>

        <div className="marginTop marginBottom">

          <Button onClick={() => this.validate()} color="info"
                  className="pull-right btn-primary">
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.next"></Translate>
                      </span>&nbsp;
            <FontAwesomeIcon icon="arrow-right"/>
          </Button>
        </div>
      </div>
    );
  }


}
